<template>
  <v-app class="app">
    <the-loading ref="loading" />
    <my-account-40-header 
      class="add-card-header"
      :account="account" 
      :firstName="firstName" 
      :lastName="lastName" 
      :clientName="clientName" 
      :myAccountAppUrl="myAccountAppUrl" 
      :invoiceToken="invoiceToken" 
      :myAccountToken="myAccountToken" 
      :showACH="showACH" 
      :hasLinkedAccounts="hasLinkedAccounts"
      />
    <v-container class="content" fluid fill-height>
      <v-flex align-self-start>
        <v-layout row fill-height class="justify-center text-xs-left">
          <v-spacer></v-spacer>
          <v-flex xs8>
            <v-card flat>
              <v-flex xs12 class="justify-center text-xs-center">
                <h1>Pay Now</h1>
              </v-flex>
              <v-card-text mt-2 v-if="showForm" style="height:100%">
                <v-layout row>
                  <v-flex xs12 mx-2 class="justify-center text-xs-center"><b>Invoice Number:</b> {{ invoiceNumber }} </v-flex>
                </v-layout>
                <v-layout row>
                  <v-flex xs12 mx-2 class="justify-center text-xs-center"><b>Invoice Date:</b> {{ formatDate(invoiceDate) }} </v-flex>
                </v-layout>
                <v-layout row>
                  <v-flex xs12 mx-2 class="justify-center text-xs-center"><b>Invoice Balance:</b> {{ invoiceAmount }} </v-flex>
                </v-layout>
                <v-layout row>
                  <v-flex xs3 />
                  <v-flex xs6 my-4 class="justify-center text-xs-center"><v-divider /></v-flex>
                  <v-flex xs3 />
                </v-layout>
                <v-layout row>
                  <v-flex xs6 mr-2 class="justify-center text-xs-right"><b>Total Payment:</b></v-flex>
                  <v-flex xs1 class="justify-center text-xs-left">
                    <v-text-field
                      class="payment-amount"
                      v-model="paymentAmount"
                      box
                      background-color="#e8f0fe"
                      hide-details
                      prefix="$"
                    />
                  </v-flex>
                  <v-flex xs5></v-flex>
                </v-layout>
                <v-layout row>
                  <v-flex xs12 mx-2 mt-3 class="justify-center text-xs-center">Please select a payment option.</v-flex>
                </v-layout>
                <v-layout row>
                  <v-radio-group class="justify-center text-xs-center mb-0" v-model="paymentMethod" row height="0px">
                    <v-radio label="Credit Card" value="ST"></v-radio>
                    <v-radio label="ACH" value="AC"></v-radio>
                  </v-radio-group>
                </v-layout>
                <add-stripe-form
                  ref="stripeForm"
                  v-if="paymentMethod === 'ST'"
                  :customer="BAN" 
                  :clientToken="clientToken"
                  :firstName="firstName"
                  :lastName="lastName"
                  :email="emailAddress"
                  :myAccountAppUrl="myAccountAppUrl"
                  :invoiceToken="invoiceToken"
                  :invoiceNumber="invoiceNumber"
                  :invoiceAmount="paymentAmount"
                  :defaultPayment="defaultPayment"
                />
                <ach-payment-form
                  ref="achForm"
                  v-if="paymentMethod === 'AC'"
                  :customer="BAN" 
                  :clientToken="clientToken"
                  :firstName="firstName"
                  :lastName="lastName"
                  :email="emailAddress"
                  :myAccountAppUrl="myAccountAppUrl"
                  :invoiceToken="invoiceToken"
                  :invoiceNumber="invoiceNumber"
                  :invoiceAmount="paymentAmount"
                  :defaultPayment="defaultPayment"
                />
                <v-checkbox size="large" class="justify-center text-xs-center pt-4 mb-0 pb-0" v-model="defaultPayment" :label="checkboxLabel" />
                <p class="justify-center text-xs-center mb-4">By clicking <b>Pay Now</b> you agree to the <router-link to="/termsconditions">Terms & Conditions</router-link> and <router-link to="/privacypolicy">Privacy Policy.</router-link></p>
                <v-flex class="justify-center text-xs-center">
                  <a @click="cancel">Cancel</a>
                  <v-btn class="ml-4 mb-0" large color="success" @click="submitPayment">Pay Now</v-btn>
                </v-flex>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-spacer></v-spacer>
        </v-layout>
      </v-flex>
    </v-container>
    <my-account-40-footer class="add-card-footer"></my-account-40-footer>
  </v-app>
</template>

<script>
import axios from "axios"
import store from "../../store"
import dayjs from 'dayjs'
import MyAccount40Footer from "@/components/MyAccount40/MyAccount40Footer.vue"
import MyAccount40Header from "@/components/MyAccount40/MyAccount40Header.vue"
import AddStripeForm from "@/components/MyAccount40/MyAccountStripeForm.vue"
import AchPaymentForm from "@/components/MyAccount40/MyAccountACHPaymentForm.vue"
import TheLoading from "@/components/TheLoading.vue"

export default {
  components: {
    /* eslint-disable vue/no-unused-components */
    MyAccount40Footer,
    MyAccount40Header,
    AddStripeForm,
    AchPaymentForm,
    TheLoading
  },
  data: () => ({
    valid: false,
    menu: false,
    BAN: "",
    myAccountToken: "",
    invoiceToken: "",
    invoiceNumber: "",
    invoiceDate: "",
    invoiceAmount: "",
    paymentAmount: "",
    last4: "",
    expiry: "",
    clientToken: "",
    clientName: "",
    clientSecret: "",
    myAccountAppUrl: "",
    paymentProvider: "",
    account: {},
    firstName: "",
    lastName: "",
    emailAddress: "",
    defaultPayment: false,
    showForm: false,
    showACH: false,
    hasLinkedAccounts: true, 
    paymentMethod: 'ST'
  }),
  async mounted() {
    this.$refs.loading.loading(true)
    this.myAccountToken = this.$route.query.token
    // this.deleteQueryString("token")
    this.invoiceToken = this.$route.query.invoicetoken
    // this.deleteQueryString("invoicetoken")
    await this.decodeMyAccountToken()
    await this.getBoss40AccountDetails()
    await this.getMyAccountUrls()
    await this.checkBusinessRules()
    this.showForm = true
    this.$refs.loading.loading(false)
  },
  computed: {
    checkboxLabel() {
      if (this.paymentMethod == 'ST') {
        return 'Sign up for autopay using this credit card.'
      } else {
        return 'Sign up for autopay using this bank account.'
      }
    },
  },
  methods: {
    async submitPayment() {
      let payment = parseFloat(this.paymentAmount.replace(',', '').replace('$', ''))
      let total = parseFloat(this.invoiceAmount.replace(',', '').replace('$', ''))
      if (payment != total) {
        let diff = payment - total
        if (!confirm(`The total payment does not match the invoice balance. Are you sure you want to ${diff > 0 ? 'overpay' : 'underpay'} this invoice by $${Math.abs(diff).toFixed(2)}?`)) return
      }
      if (this.defaultPayment) {
        this.account.paymentMethodId = this.paymentMethod
        await axios.post(process.env.VUE_APP_PGAPI + 'api/boss40/editaccount', { "account": this.account, "payGateToken": this.clientToken })
      }
      if (this.paymentMethod == 'ST') {
        await this.$refs.stripeForm.submit('PayNow')
      } else {
        await this.$refs.achForm.submit('PayNow')
      }
    },
    async decodeMyAccountToken(){
      await axios
        .post(process.env.VUE_APP_PGAPI + `api/boss40/DecodeMyAccountToken/?token=${this.myAccountToken}`)
        .then((resp) => {
          this.clientToken = resp.data.PaygateToken
          this.BAN = resp.data.AccountNumber
          store.state.BAN = resp.data
          this.clientName = resp.data.Client
          this.emailAddress = resp.data.Email
          this.firstName = resp.data.FirstName
          this.lastName = resp.data.LastName
          this.paymentProvider = resp.data.PaymentProvider
          this.invoiceNumber = resp.data.InvoiceNumber
          this.invoiceAmount = this.formatAmount(resp.data.InvoiceAmount)
          this.paymentAmount = this.formatAmount(resp.data.InvoiceAmount).replace('$', '')
          this.invoiceDate = resp.data.InvoiceDate
        })
    },
    async getBoss40AccountDetails(){
      await axios
        .post(process.env.VUE_APP_PGAPI + "api/boss40/getaccount", {"accountNumber" : this.BAN, "payGateToken" : this.clientToken })
        .then((resp) => {
          this.account = resp.data;
          store.state.account = resp.data
        });
    },
    async getMyAccountUrls() {
      await axios
      .post(process.env.VUE_APP_PGAPI + "api/config/getmyaccounturls", { clientToken: this.clientToken })
      .then((resp) => {
        this.myAccountAppUrl = resp.data.MyAccountAppUrl
        this.clientName = resp.data.ClientName
      })
    },
    async checkBusinessRules() {
      await axios
        .post(process.env.VUE_APP_PGAPI + 'api/boss40/getbusinessrules', { payGateToken: this.clientToken, Filters: { Rules: ["MYACH"] } })
        .then((res) => {
          if (res.data) {
            let achRule = JSON.parse(res.data).find(a => a.Id == "MYACH")
            if (achRule.Value === 'TRUE') this.showACH = true
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    cancel() {
      location.assign(`${this.myAccountAppUrl}/billing-history?token=${this.invoiceToken}`)
    },
    deleteQueryString(queryString) {
      let query = Object.assign({}, this.$route.query);
      delete query[`${queryString}`];
      this.$router.replace({ query });
    },
    formatDate(date) {
      if (date) {
        let YYYYMMDD = dayjs(date, 'YYYY-MM-DD')
        return YYYYMMDD.format('DD MMM, YYYY')
      }
    },
    formatAmount(value) {
      var absValue = Math.abs(value)
      var returnString = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      }).format(absValue)
      return value < 0 ? '-' + returnString : returnString
    }
  }
}
</script>

<style scoped>
.app {
  width: 100%;
  background-color: #EEEEEE;
}
.add-card-header {
  min-height: 100px;
  background-color: white;
  min-width: 1133px;
  width: 60%;
  margin: 0 auto;
}
.add-card-footer {
  position: relative !important;
  min-width: 1133px !important;
  width: 60% !important;
  margin: 0 auto;
}
.page-title {
  font-size: x-large;
  font-weight: bold;
}
.content {
  position: relative;
  min-width: 1133px;
  width: 60%;
  margin: 0 auto;
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: white;
}
.whitebg {
  background-color: white;
}
.action-bars {
  background-color: #f0f0f0 !important;
  min-height: 52px;
  max-height: 52px;
}
.card-title{
    font-family: Roboto, sans-serif;
    font-size: 20px;
}
.form-field-label{
    color: grey;
    font-family: Roboto, sans-serif;
    font-size: 12px;
}
.form-field{
    border-bottom-width: 1px;
    border-bottom-color: lightgray;
    border-bottom-style: solid;
}
/deep/div.v-input.v-input--selection-controls.v-input--checkbox {
  margin-top: 0px;
  padding-top: 0px;
}
/deep/ .payment-amount .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
  padding: 3px !important;
  width: 110px !important;
  height: 30px !important;
  margin-top: -5px !important;
}
/deep/ .payment-amount div.v-text-field__prefix {
  padding: 0 !important;
  margin: 0 !important;
  margin-right: 5px !important;
}
/deep/ .payment-amount div.v-text-field__slot input{
  padding: 0 !important;
  margin: 0!important;
}
/deep/.v-label.theme--light {
  color: black;
}
</style>