<template>
    <v-container fluid pa-0 mt-1 mb-0 fill-height>
        <the-loading ref="loading" />
        <v-layout row>
            <v-flex xs2 />
            <v-flex xs8>
                <v-form
                v-model="valid"
                @submit="submit"
                ref="saveACHForm"
                lazy-validation
                style="height:100%"
              >
                <v-layout row>
                  <v-flex xs6 mr-2>
                    <v-text-field
                      name="accountHolderName"
                      v-model="accountHolderName"
                      :rules="requiredRule"
                      box
                      background-color="#e8f0fe"
                      ><template slot="label">
                        Account Holder Name<red-asterisk></red-asterisk></template>
                    </v-text-field>
                  </v-flex>
                  <v-flex xs6 ml-2>
                    <v-select
                      :items="accountHolderTypes"
                      v-model="accountHolderType"
                      :rules="accountHolderTypeRule"
                      autocomplete
                      box
                      background-color="#e8f0fe"
                    ><template slot="label">Account Type<red-asterisk></red-asterisk></template></v-select>
                  </v-flex>
                </v-layout>
                <v-layout row>
                  <v-flex xs6 mr-2>
                    <v-text-field
                      name="bankAccountNumber"
                      v-model="bankAccountNumber"
                      :rules="accountNumberRule"
                      box
                      background-color="#e8f0fe"
                      ><template slot="label">
                        Account Number<red-asterisk></red-asterisk></template>
                    </v-text-field>
                  </v-flex>
                  <v-flex xs6 ml-2>
                    <v-text-field
                      name="bankRoutingNumber"
                      v-model="bankRoutingNumber"
                      :rules="routingNumberRule"
                      :mask="routingNumberMask"
                      box
                      background-color="#e8f0fe"
                      ><template slot="label">
                        Routing Number<red-asterisk></red-asterisk></template>
                    </v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row>
                  <v-flex xs6 mr-2>
                    <v-text-field
                      name="email"
                      v-model="email"
                      :rules="emailRules"
                      box
                      background-color="#e8f0fe"
                      ><template slot="label">
                        Account Holder Email<red-asterisk></red-asterisk></template>
                    </v-text-field>
                  </v-flex>
                  <v-flex xs6 ml-2>
                    <v-text-field
                      name="phoneNumber"
                      v-model="phoneNumber"
                      :rules="requiredRule"
                      :mask="phoneNumberMask"
                      box
                      background-color="#e8f0fe"
                      ><template slot="label">
                        Phone Number<red-asterisk></red-asterisk></template>
                    </v-text-field>
                  </v-flex>
                </v-layout>
                </v-form>
            </v-flex>
            <v-flex xs2 />
        </v-layout>
    </v-container>
</template>

<script>
import axios from "axios"
import TheLoading from "@/components/TheLoading.vue"
import RedAsterisk from "@/components/RedAsterisk.vue"
import Bus from '../../bus'

export default {
    components: {
        TheLoading,
        RedAsterisk
    },
    props: {
        customer: String,
        clientToken: String,
        firstName: String,
        lastName: String,
        email: String,
        invoiceToken: String,
        myAccountAppUrl: String,
        invoiceNumber: String,
        invoiceAmount: Number,
        invoices: Array,
        defaultPayment: Boolean
    },
    data() {
        return {
            valid: false,
            menu: false,
            requestId: null,
            phoneNumber: "",
            accountHolderName: "",
            accountHolderType: "Please Select",
            bankAccountNumber: "",
            bankRoutingNumber: "",
            accountHolderTypes: [
            {value: "Please Select", text: "Please Select"},
              {value: "BUSINESS_CHECKING", text: "Business Checking"},
              {value: "BUSINESS_SAVINGS", text: "Business Savings"},
              {value: "PERSONAL_CHECKING", text: "Personal Checking"},
              {value: "PERSONAL_SAVINGS", text: "Personal Savings"}
            ],
            phoneNumberMask: "(###) ###-####",
            routingNumberMask: "#########",
            emailRules: [
              (v) => !!v || "Required",
              (v) =>
                /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                  v
                ) || "E-mail must be valid",
            ],
            accountHolderTypeRule: [(v) => v != "Please Select" || "Required"],
            accountNumberRule: [(v) => v.length > 5 && v.length < 18 || "Account Number must be 6 to 17 digits long."],
            routingNumberRule: [(v) => v.length == 9 || "Routing Number must be 9 digits."],
            requiredRule: [(v) => !!v || "Required"],
        };
    },
    methods: {
        async submit(formType){
            if (this.$refs.saveACHForm.validate()) {
              this.$refs.loading.processing(true)
              if (this.requestId == null) {
                this.requestId = crypto.randomUUID()
              }
              let paymentObject = {
                payGateToken: this.$props.clientToken,
                BAN: this.$props.customer,
                email: this.$props.email,
                UserName: 'myaccount@vizibill.com',
                DefaultPaymentMethod: this.$props.defaultPayment,
                RequestId: this.requestId,
                QuickbooksACHDetails: {
                  amount: parseFloat(this.$props.invoiceAmount.replace(',', '').replace('$', '')).toFixed(2),
                  description: `Payment for invoice ${this.$props.invoiceNumber} on account ${this.$props.customer}`,
                  paymentMode: "WEB",
                  bankAccount: {
                    phone: this.phoneNumber,
                    name: this.accountHolderName,
                    accountType: this.accountHolderType,
                    accountNumber: this.bankAccountNumber,
                    routingNumber: this.bankRoutingNumber
                  }
                }
              }
              if(formType == 'PayNow') {
                paymentObject.InvoiceNumber = this.$props.invoiceNumber,
                paymentObject.InvoiceQuantity = "Single"
                await axios.post(process.env.VUE_APP_PGAPI + "api/ach/payment", paymentObject)
                .then(res => {
                    Bus.$emit('alert', {
                      color: 'success',
                      message: 'Payment processed successfully!'
                    })
                    location.assign(`${this.$props.myAccountAppUrl}/billing-history?token=${this.invoiceToken}&status=success`)
                })
                .catch(err => {
                  Bus.$emit('alert', {
                    color: 'error',
                    message: `There was an error processing your payment, please try again.`
                  })
                })
              } else if (formType == 'PayAll') {
                paymentObject.Invoices = this.$props.invoices
                paymentObject.InvoiceQuantity = "Multiple"
                await axios.post(process.env.VUE_APP_PGAPI + "api/ach/pay-all", paymentObject)
                .then(res => {
                  Bus.$emit('alert', {
                    color: 'success',
                    message: 'Payment processed successfully!'
                  })
                  location.assign(`${this.$props.myAccountAppUrl}/billing-history?token=${this.invoiceToken}&status=success`)
                })
                .catch(err => {
                  Bus.$emit('alert', {
                    color: 'error',
                    message: `There was an error processing your payment, please try again.`
                  })
                })
              }
              this.$refs.loading.processing(false)
            }
        }
    }
}
</script>