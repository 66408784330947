<template>
  <v-container class="content" fluid pa-0 mt-4 fill-height>
    <the-loading ref="loading" />
    <v-layout row fill-height class="justify-center text-xs-center">
      <v-spacer></v-spacer>
      <v-flex xs6>
        <v-card flat>
          <v-card-text style="height:100%">
            <v-form
              lazy-validation
              style="height:100%"
            >
              <v-layout row>
                <v-text-field
                  name="ccNumber"
                  prepend-inner-icon="fa-credit-card"
                  v-model="ccDetails.ccNumber"
                  mask="credit-card"
                  placeholder="1234 - 5678 - 90123 - 4567"
                  :rules="CardNumberRules"
                  ><template slot="label">
                    Credit Card Number<red-asterisk></red-asterisk></template
                ></v-text-field>
              </v-layout>
              <v-layout row>
                <v-flex xs3>
                  <v-menu
                    v-model="menu"
                    :close-on-content-click="true"
                    :nudge-right="40"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    min-width="290px"
                  >
                    <v-text-field
                      style="max-height: 44px"
                      slot="activator"
                      v-model="ccDetails.ccExpiry"
                      prepend-inner-icon="fa-calendar-alt"
                      placeholder="YYYY-MM"
                      readonly
                      required
                      validate-on-blur
                      :rules="CardExpiryRules"
                      ><template slot="label">
                        Expiry Date<red-asterisk></red-asterisk></template
                    ></v-text-field>
                    <v-date-picker
                      v-model="ccDetails.ccExpiry"
                      type="month"
                      :month-format="getMonths"
                      @input="menu = false"
                      @change="changeCcDetails"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs6> </v-flex>
                <v-flex xs3 class="text-xs-right">
                  <v-text-field
                    name="ccCVD"
                    v-model="ccDetails.ccCVD"
                    maxlength="4"
                    type="number"
                    placeholder="●●●●"
                    prepend-inner-icon="fa-lock"
                    :rules="cvdRules"
                    @change="changeCcDetails"
                    ><template slot="label">
                      Verification Digits<red-asterisk></red-asterisk
                    ></template>
                  </v-text-field>
                </v-flex>
              </v-layout>

              <v-layout row text-xs-left title mt-2>
                <v-flex shrink mr-3>
                  Billing Information
                </v-flex>
                <v-flex grow ml-3>
                  <v-checkbox
                    label="Same as Account Information"
                    v-model="isSameAccountInfo"
                    @change="setBillingInformation"
                  ></v-checkbox>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs4>
                  <v-text-field
                    name="ccName"
                    v-model="ccPerson.ccName"
                    placeholder="e.g. Akihiro Akiyama"
                    :rules="nameRequiredRule"
                    :disabled="isSameAccountInfo"
                    ><template slot="label">
                      Name on Card<red-asterisk></red-asterisk></template
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 mr-3>
                  <v-text-field
                    name="ccStreetNumber"
                    v-model="ccAddress.streetNumber"
                    placeholder="e.g. 30"
                    maxlength="10"
                    :rules="streetNumRequiredRule"
                    :disabled="isSameAccountInfo"
                    @change="changeCcAddress"
                    ><template slot="label">
                      Street Number<red-asterisk></red-asterisk></template
                  ></v-text-field>
                </v-flex>
                <v-flex xs7 mx-3>
                  <v-text-field
                    name="ccStreetName"
                    v-model="ccAddress.streetName"
                    placeholder="e.g. Durham Street South"
                    maxlength="20"
                    :rules="streetRequiredRule"
                    :disabled="isSameAccountInfo"
                    @change="changeCcAddress"
                    ><template slot="label">
                      Street Name<red-asterisk></red-asterisk></template
                  ></v-text-field>
                </v-flex>
                <v-flex xs3 ml-3>
                  <v-text-field
                    name="ccZipPostalCode"
                    v-model="ccAddress.postalZip"
                    placeholder="e.g. M9V3L3 or 12345"
                    maxlength="10"
                    required
                    :rules="postalRequiredRule"
                    :disabled="isSameAccountInfo"
                    @change="changeCcAddress"
                    ><template slot="label">
                      Zip/Postal Code<red-asterisk></red-asterisk></template
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs6 mr-3>
                <v-text-field
                  name="ccEmailAddress"
                  v-model="ccPerson.email"
                  placeholder="e.g. aa@acmeinstallers.com"
                  maxlength="128"
                  :rules="emailRules"
                  required
                  :disabled="isSameAccountInfo"
                  ><template slot="label">
                    Email Address<red-asterisk></red-asterisk></template
                ></v-text-field>
                </v-flex>
                <v-flex xs6 ml-3>
                <v-text-field
                  name="ccPhoneNumber"
                  v-model="ccPerson.phoneNumber"
                  placeholder="e.g. (705)-777-7777"
                  maxlength="10"
                  :rules="phoneNumberRequiredRule"
                  :mask="phoneNumberMask"
                  required
                  :disabled="isSameAccountInfo"
                  ><template slot="label">
                    Phone Number<red-asterisk></red-asterisk></template
                ></v-text-field>
                </v-flex>
              </v-layout>
            </v-form>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-spacer></v-spacer>
    </v-layout>
  </v-container>
</template>

<script>
import axios from "axios";
import RedAsterisk from "@/components/RedAsterisk.vue";
import TheLoading from "@/components/TheLoading.vue"
import Bus from '../../bus'

export default {
    name: "VizibillMonerisForm",
    components: {
    TheLoading,
    RedAsterisk,
  },
    props: {
      customer: String,
      clientToken: String,
      name: String,
      email: String,
      accountToken: String,
      vizibillAppUrl: String,
      invoiceNumber: String,
      invoiceAmount: String,
      invoices: Array,
      paymentMethod: String,
      defaultPayment: Boolean,
      userName: String,
      account: Object
    },
    data: () => ({
        ccPerson: {
            ccName: "",
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: ""
        },
        ccAddress: {
            streetNumber: "",
            streetName: "",
            aptSuite: "",
            city: "",
            stateProv: "",
            postalZip: ""
        },
        ccDetails: {
            ccNumber: "",
            ccExpiry: "",
            ccCVD: ""
        },
        menu: false,
        formType: "",
        boss40ApiUrl: "",
        isSameAccountInfo: false,

        mailCodeMask: "",
        mailCodeRegex: "",
        phoneNumberMask: "(###) ###-####",

        CardNumberRules: [
            v => !!v || "Card Number is required",
            v =>
                /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/.test(
                v
                ) || "Invalid Card Number"
        ],
        CardExpiryRules: [
            v => !!v || "",
            v => (v && v.length == 4) || "Invalid Card Expiry Date"
        ],
        charFilter:[
            v => /^[0-9a-zA-Z ]*$/.test(v) || "Invalid characters"
        ],
        requiredRules: [v => !!v || "",
            v => /^[0-9a-zA-Z ]{1,50}$/.test(v) || "Invalid characters"],
        expiryRules: [v => !!v || "",
            v => /^[0-9\\-]{7}$/.test(v) || "Invalid Year/Month"
        ],
        mailCodeRules: [v => !!v || ""],
        cvdRules: [
          (v) => !!v || "Card Validation Digits is required",
          (v) =>
            /^[0-9]{3,4}$/.test(v) ||
            "Card Validation Digits requires 3 or 4 digits",
        ],
        postalRequiredRule: [(v) => !!v || "Postal/Zip Code is required"],
        streetRequiredRule: [(v) => !!v || "Street Name is required"],
        streetNumRequiredRule: [(v) => !!v || "Street Number is required", (v) => /^[0-9]{1,10}$/.test(v) || "Must be a number"],
        nameRequiredRule: [(v) => !!v || "Required"],
        phoneNumberRequiredRule: [(v) => !!v || "Phone number is required"],
        emailRules: [
          (v) => !!v || "E-mail is required",
          (v) =>
            /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              v
            ) || "E-mail must be valid",
        ],
        months: [
          "02 - FEB",
          "03 - MAR",
          "04 - APR",
          "05 - MAY",
          "06 - JUN",
          "07 - JUL",
          "08 - AUG",
          "09 - SEP",
          "10 - OCT",
          "11 - NOV",
          "12 - DEC",
          "01 - JAN",
        ]
    }),

    mounted(){
        this.ccPerson.firstName = this.$props.account.billTo_Name1;
        this.ccPerson.lastName = this.$props.account.billTo_Name2;
        this.changeCcPerson();
        this.ccAddress.city = this.$props.account.cityName;
        this.ccAddress.stateProv = this.$props.account.stateProvId;
        this.ccAddress.postalZip = this.$props.account.mailCode;
        this.seperateAddress(this.$props.account.Address1)
        this.changeCcAddress();
    },

    methods: {
      async submit(formType, cardId){
          this.$refs.loading.processing(true)
          this.formType = formType
          let card = {
                BAN: this.$props.customer,
                payGateToken: this.$props.clientToken,
                email: this.$props.email,
                name: this.$props.name,
                
                ccPerson: this.ccPerson,
                ccAddress: this.ccAddress,
                ccDetails: this.ccDetails,

                Amount: this.$props.invoiceAmount,
                Currency: 'usd',
                DefaultPayment: this.$props.defaultPayment,
                UserName: this.$props.userName,
                CardOnFile: this.cardOnFile,
                CardOnFileId: cardId
            }
            card.InvoiceNumber = this.$props.invoiceNumber
            card.InvoiceQuantity = "Single"
            await axios.post(process.env.VUE_APP_PGAPI + "api/card/payment", card)
              .then(res => {
                if (res.status == 200) {
                  Bus.$emit('alert', {
                      color: 'success',
                      message: 'Payment processed successfully!'
                  })
                  this.$router.push({ 
                      name: 'PaymentConsole',
                      query: {
                          token: this.accountToken,
                      }
                  })
                }
              })
              .catch((error) =>{
                Bus.$emit('alert', {
                  color: 'error',
                  message: `There was an error processing your payment, please try again.`
                })
                console.log(error)
              });
          this.$refs.loading.processing(false)
      },
      submitExistingCard(formType, cardId){
          this.cardOnFile = true
          this.submit(formType, cardId)
      },
      async getBoss40Urls() {
        await axios.post(process.env.VUE_APP_PGAPI + 'api/config/getboss40urls',{ "clientToken": this.clientToken })
        .then((resp) => {
          this.boss40AppUrl = resp.data.Boss40AppUrl
          this.boss40ApiUrl = resp.data.Boss40ApiUrl
          this.clientName = resp.data.ClientName
        });
      },
      getMailCodeMask() {
          let config = {
              headers: {
              'Authorization': 'Bearer ' + this.token
              }
          }
          axios
              .get(this.boss40ApiUrl + 'api/Account/GetMailCodeMask/' + this.ccAddress.stateProv, config)
              .then(resp => {
              if (resp.data !== " ") {
                  this.mailCodeMask = resp.data.split(",")[0];
                  this.mailCodeRegex = resp.data.split(",")[1];
                  this.mailCodeRules = [
                  v => !!v || "",
                  v =>
                      new RegExp(resp.data.split(",")[1]).test(v) ||
                      "Not a valid Postal/Zip Code. Match format " +
                      resp.data.split(",")[2]
                  ];
              } else {
                  this.mailCodeMask = "";
                  this.mailCodeRegex = "";
                  this.mailCodeRules = [];
              }
          })
          .catch(err => console.log(err));

          this.changeCcAddress();
      },
      changeCcPerson(){
          this.$emit('changeccperson', this.ccPerson);
      },
      changeCcAddress(){
          this.$emit('changeccaddress', this.ccAddress);
      },
      changeCcDetails(){
          this.$emit('changeccdetails', this.ccDetails);
      },
      setBillingInformation() {
        if (this.isSameAccountInfo) {
          this.ccAddress.postalZip = this.$props.account.mailCode
          this.ccPerson.email = this.$props.account.contacts[0].ACEMAIL
          this.ccPerson.phoneNumber = this.$props.account.contacts[0].ACPHONE
          this.ccPerson.ccName = this.$props.account.contacts[0].ACNAME1 + ' ' + this.$props.account.contacts[0].ACNAME2
          this.ccPerson.firstName = this.$props.account.contacts[0].ACNAME1
          this.ccPerson.lastName = this.$props.account.contacts[0].ACNAME2
          this.seperateAddress(this.$props.account.Address1)
        }
      },
      seperateAddress(addr) {
        // splits the address at the 1st whitespace
        var splitAddress = addr.split(/(?<=^\S+)\s/);
        this.ccAddress.streetNumber = splitAddress[0];
        this.ccAddress.streetName = splitAddress[1];
      },
      getMonths(month) {
        let i = new Date(month).getMonth(month)
        return this.months[i]
      }
    }
}
</script>

<style scoped>
/deep/div.v-input.v-input--selection-controls.v-input--checkbox {
  margin-top: 0px;
  padding-top: 0px;
}
</style>