<template>
  <v-dialog
    v-model="dialog"
    class="loading"
    persistent
    width="120"
  >
    <v-card
      color="grey"
      dark
      width="120"
    >
      <v-card-text>
        {{ message }}...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>

export default {
  data: () => ({
    dialog: false,
    message: ''
  }),
  methods: {
    async loading(enable) {
      this.dialog = enable
      this.message = 'Loading'
    },
    async processing(enable) {
      this.dialog = enable
      this.message = 'Processing'
    }
  }
}
</script>
<style scoped>
.loading {
  z-index: 99999 !important;
}
</style>