<template>
  <v-app class="app">
    <the-loading ref="loading" />
    <v-container class="content" fluid fill-height>
      <v-flex align-self-start>
        <v-layout row fill-height class="justify-center text-xs-left">
          <v-flex>
            <v-card class="content" flat>
              <v-layout>
                <v-flex xs2 pl-4>
                  <v-img
                    src="@/assets/img/vizibillLogo.png"
                    cover
                    min-height="40"
                    max-height="70"
                    min-width="90"
                    max-width="150"
                  ></v-img>
                </v-flex>
                <v-flex xs8 class="justify-center text-xs-center">
                  <h1>Pay All {{ isParent ? '(including children)' : '' }}</h1>
                  <h3 pt-2>{{ BAN }} - {{ name }}</h3>
                </v-flex>
                <v-flex xs2></v-flex>
              </v-layout>
              <v-card-text mt-2 v-if="showForm" style="height:100%">
                <v-layout row class="justify-center">
                  <v-data-table
                    :headers="headers"
                    :items="openInvoices"
                    :pagination.sync="pagination"
                    :rows-per-page-items="[5]"
                    select-all
                    item-key="DocId"
                  >
                    <template v-slot:headers="props">
                      <tr>
                        <th>
                          <v-checkbox
                            :input-value="props.all"
                            :indeterminate="props.indeterminate"
                            v-model="selectAll"
                            primary
                            hide-details
                            @click.stop="toggleAll"
                          ></v-checkbox>
                        </th>
                        <th v-if="isParent"></th>
                        <th
                          v-for="header in props.headers"
                          :key="header.text"
                          :class="header.class"
                        >{{ header.text }}</th>
                      </tr>
                    </template>
                    <template v-slot:items="props">
                      <td class="text-md-left">
                        <v-checkbox
                          v-model="selected"
                          primary
                          hide-details
                          :value="props.item"
                        ></v-checkbox>
                      </td>
                      <td v-if="isParent">
                        <v-chip 
                          class="chip"
                          small
                          :color="props.item.AccountBAN == BAN ? '#dfdfff' : '#fef0df'"
                          :text-color="props.item.AccountBAN == BAN ? '#0000ff' : '#fb8c00'" 
                        >{{ props.item.AccountBAN == BAN ? 'Parent' : 'Child' }}</v-chip>
                      </td>
                      <td class="text-md-left">{{ props.item.DocId }}</td>
                      <td v-if="isParent" class="text-md-left">{{ props.item.AccountBAN }}</td>
                      <td class="text-md-left">{{ formatDate(props.item.Date) }}</td>
                      <td class="text-md-right">{{ formatAmount(props.item.Balance) }}</td>
                    </template>
                    <template v-slot:actions-append>
                      <span style="color: black; position: relative; top: 10px !important; margin-left: 140px; margin-right: 20px;"><b>Selected Total:</b> {{ totalAmount }}</span>
                    </template>
                    <template v-slot:no-data>
                      <td colspan="4" class="text-md-center"><span color="success">No pending invoices on this account.</span></td>
                    </template>
                  </v-data-table>
                </v-layout>
                <v-layout row mt-4>
                  <v-flex xs6 mr-2 class="justify-center text-xs-right"><b>Total Payment:</b></v-flex>
                  <v-flex xs1 class="justify-center text-xs-left">
                    <v-text-field
                      class="payment-amount"
                      v-model="paymentAmount"
                      outline
                      hide-details
                      prefix="$"
                    />
                  </v-flex>
                  <v-flex xs5></v-flex>
                </v-layout>
                <v-layout row>
                  <v-flex xs12 mx-2 mt-3 class="justify-center text-xs-center">Please select a payment option.</v-flex>
                </v-layout>
                <v-layout column>
                  <v-radio-group v-if="clientName == 'Atlas'" column class="justify-center text-xs-center mb-0" v-model="paymentMethod">
                    <v-radio label="New Credit Card" value="ST"></v-radio>
                    <v-radio label="New ACH" value="AC"></v-radio>
                    <v-radio v-if="hasExistingCC" :label="`Credit Card on File: **** ${existingCCLast4}`" value="ECC"></v-radio>
                    <v-radio v-if="hasExistingACH" :label="`ACH on File: **** ${existingACHLast4}`" value="EAC"></v-radio>
                  </v-radio-group>
                  <v-radio-group v-if="clientName == 'Mircom'" column class="justify-center text-xs-center mb-0" v-model="paymentMethod">
                    <!-- <v-radio-group v-if="clientName == 'BOSS40Local'" column class="justify-center text-xs-center mb-0" v-model="paymentMethod"> -->
                    <v-radio label="Visa/Mastercard" value="MO"></v-radio>
                    <v-radio label="AMEX" value="ST"></v-radio>
                    <v-radio v-if="hasExistingCC" :label="`Credit Card on File: **** ${existingCCLast4}`" value="ECC"></v-radio>
                  </v-radio-group>
                </v-layout>
                <vizibill-moneris-form
                  ref="monerisForm"
                  v-show="paymentMethod === 'MO'"
                  :customer="BAN"
                  :clientToken="clientToken"
                  :name="invoiceDetails.AccountName1"
                  :email="emailAddress"
                  :accountToken="accountToken"
                  :invoiceNumber="invoiceDetails.Description"
                  :invoiceAmount="paymentAmount"
                  :defaultPayment="defaultPayment"
                  :userName="userName"
                  :account="account"
                />
                <vizibill-stripe-form
                  ref="stripeForm"
                  v-show="paymentMethod === 'ST'"
                  :customer="BAN" 
                  :clientToken="clientToken"
                  :email="emailAddress"
                  :accountToken="accountToken"
                  :invoices="selected"
                  :name="invoiceDetails.AccountName1"
                  :invoiceAmount="paymentAmount"
                  :defaultPayment="defaultPayment"
                  :userName="userName"
                />
                <vizibill-ach-payment-form
                  ref="achForm"
                  v-show="paymentMethod === 'AC'"
                  :customer="BAN" 
                  :clientToken="clientToken"
                  :email="emailAddress"
                  :accountToken="accountToken"
                  :invoices="selected"
                  :invoiceAmount="paymentAmount"
                  :defaultPayment="defaultPayment"
                  :userName="userName"
                />
                <v-checkbox size="large" class="justify-center text-xs-center pt-4" v-model="defaultPayment" :label="checkboxLabel" />
                <p class="justify-center text-xs-center mt-4 mb-4">By clicking <b>Pay Now</b> you agree to the <router-link to="/termsconditions">Terms & Conditions</router-link> and <router-link to="/privacypolicy">Privacy Policy.</router-link></p>
                <v-flex class="justify-center text-xs-center">
                  <a @click="cancel">Cancel</a>
                  <v-btn class="ml-4" large color="success" @click="submitPayment">Pay Now</v-btn>
                </v-flex>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-container>
  </v-app>
</template>

<script>
import axios from "axios"
import dayjs from 'dayjs'
import store from "../../store"
import VizibillStripeForm from "../../components/PaymentConsole/VizibillStripeForm.vue"
import VizibillAchPaymentForm from "../../components/PaymentConsole/VizibillACHPaymentForm.vue"
import VizibillMonerisForm from "../../components/PaymentConsole/VizibillMonerisForm.vue"
import TheLoading from "@/components/TheLoading.vue"

export default {
  components: {
    VizibillStripeForm,
    VizibillAchPaymentForm,
    VizibillMonerisForm,
    TheLoading
  },
  data: () => ({
    valid: false,
    menu: false,
    BAN: "",
    accountToken: "",
    clientToken: "",
    userName: "",
    paymentAmount: null,
    paymentProvider: "",
    account: {},
    emailAddress: "",
    defaultPayment: false,
    showForm: false,
    showACH: false,
    paymentMethod: 'ST',
    hasExistingACH: false,
    existingACHLast4: '',
    existingACHId: '',
    hasExistingCC: false,
    existingCCLast4: '',
    existingCCId: '',
    isParent: false,
    name: "",
    selected: [],
    openInvoices: [],
    invoices: [],
    invoiceDetails: {},
    
    pagination: { rowsPerPage: 5 },
    headers: [
      {
        text: "Invoice Number",
        value: "invoiceNumber",
        class: 'font-weight-bold subheading'
      },
      {
        text: "Account Number",
        value: "accountNumber",
        class: 'font-weight-bold subheading'
      },
      {
        text: "Date",
        value: "invoiceDate",
        class: 'font-weight-bold subheading text-xs-left'
      },
      {
        text: "Balance",
        value: "invoiceAmount",
        class: 'font-weight-bold subheading text-xs-right'
      }
    ],
  }),
  async mounted() {
    this.$refs.loading.loading(true)
    let invoiceDetails = store.state.adminPayConsoleDetails.invoiceDetails
    this.accountToken = store.state.adminPayConsoleDetails.accountToken
    this.clientToken = store.state.adminPayConsoleDetails.clientToken
    this.isParent = store.state.adminPayConsoleDetails.isParent
    if (this.clientName == 'Mircom') this.paymentProvider = 'MO'
    // if (this.clientName == 'BOSS40Local') this.paymentProvider = 'MO'
    this.BAN = store.state.adminPayConsoleDetails.BAN
    this.emailAddress = store.state.adminPayConsoleDetails.emailAddress
    this.userName = store.state.adminPayConsoleDetails.userName
    this.name = store.state.adminPayConsoleDetails.name
    this.account = store.state.adminPayConsoleDetails.account
    this.openInvoices = Object.keys(invoiceDetails).map(function(k) { return invoiceDetails[k] })
    this.selected = this.openInvoices

    if (!this.isParent) this.headers.splice(1, 1)

    await this.checkBusinessRules()
    await this.checkExistingPaymentMethods()
    this.showForm = true
    this.$refs.loading.loading(false)
  },
  watch: {
    totalAmount() {
      this.paymentAmount = this.totalAmount.replace('$', '')
    }
  },
  computed: {
    checkboxLabel() {
      if (this.paymentMethod == 'ST') {
        return 'Sign up for autopay using this credit card.'
      } else {
        return 'Sign up for autopay using this bank account.'
      }
    },
    totalAmount() {
      return this.formatAmount(this.selected.reduce((acc, cur) => acc + parseFloat(cur.Balance), 0).toFixed(2))
    },
    selectAll() {
      if (this.selected.length === this.openInvoices.length) return true
      else return false
    }
  },
  methods: {
    async submitPayment() {
      let payment = parseFloat(this.paymentAmount.replace(',', '').replace('$', ''))
      let total = parseFloat(this.totalAmount.replace(',', '').replace('$', ''))
      if (payment != total) {
        let diff = payment - total
        if (!confirm(`The total payment does not match the invoice balance. Are you sure you want to ${diff > 0 ? 'overpay' : 'underpay'} this invoice by $${Math.abs(diff).toFixed(2)}?`)) return
      }
      if (this.defaultPayment) {
        this.account.paymentMethodId = this.paymentMethod
        await axios.post(process.env.VUE_APP_PGAPI + 'api/boss40/editaccount', { "account": this.account, "payGateToken": this.clientToken })
      }
      switch (this.paymentMethod) {
        case 'ST':
          await this.$refs.stripeForm.submit('PayAll')
          break
        case 'ECC':
          await this.$refs.stripeForm.submitExistingCard('PayAll', this.existingCCId)
          break
        case 'MO':
          await this.$refs.monerisForm.submit('PayAll')
          break
        case 'MOECC':
          await this.$refs.monerisForm.submitExistingCard('PayAll', this.existingCCId)
          break
        case 'AC':
          await this.$refs.achForm.submit('PayAll')
          break
        case 'EAC':
          await this.$refs.achForm.submit('PayAll', this.existingACHId)
          break
      }
    },
    async checkExistingPaymentMethods() {
      if (this.clientName == 'Atlas') {
        await axios
          .post(process.env.VUE_APP_PGAPI + 'api/ach/read', { payGateToken: this.clientToken, BAN: this.BAN})
          .then((res) => {
            if (res.data && res.data.Result) {
              this.hasExistingACH = true
              this.existingACHLast4 = res.data.Result.accountNumber.slice(-4)
              this.existingACHId = res.data.Result.id
            }
          })
          .catch(err => {
            console.log(err)
          })
      }
      await axios
        .post(process.env.VUE_APP_PGAPI + 'api/card/read', { payGateToken: this.clientToken, BAN: this.BAN})
        .then((res) => {
          if (res.data) {
            this.hasExistingCC = true
            this.existingCCLast4 = res.data.Result.data ? res.data.Result.data[0].card.last4 : res.data.Result.last4
            this.existingCCId = res.data.Result.data[0].id
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    async checkBusinessRules() {
      await axios
        .post(process.env.VUE_APP_PGAPI + 'api/boss40/getbusinessrules', { payGateToken: this.clientToken, Filters: { Rules: ["MYACH"] } })
        .then((res) => {
          if (res.data) {
            let achRule = JSON.parse(res.data).find(a => a.Id == "MYACH")
            if (achRule.Value === 'TRUE') this.showACH = true
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    cancel() {
      this.$router.push({ 
        name: 'PaymentConsole',
        query: {
          token: this.accountToken,
        }
      })
    },
    deleteQueryString(queryString) {
      let query = Object.assign({}, this.$route.query);
      delete query[`${queryString}`];
      this.$router.replace({ query });
    },
    formatDate(date) {
      if (date) {
        let YYYYMMDD = dayjs(date, 'YYYY-MM-DD')
        return YYYYMMDD.format('DD MMM, YYYY')
      }
    },
    formatAmount(value) {
      var absValue = Math.abs(value)
      var returnString = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      }).format(absValue)
      return value < 0 ? '-' + returnString : returnString
    },
    toggleAll () {
      if (this.selected.length) this.selected = []
      else this.selected = this.openInvoices.slice()
    },
  },
};
</script>

<style scoped>
.app {
  width: 100%;
  background-color: #EEEEEE;
}
.content {
  position: relative;
  min-width: 1300px;
  width: 60%;
  margin: 0 auto;
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: white;
}
.whitebg {
  background-color: white;
}
.action-bars {
  background-color: #f0f0f0 !important;
  min-height: 52px;
  max-height: 52px;
}
.card-title{
    font-family: Roboto, sans-serif;
    font-size: 20px;
}
.form-field-label{
    color: grey;
    font-family: Roboto, sans-serif;
    font-size: 12px;
}
.form-field{
    border-bottom-width: 1px;
    border-bottom-color: lightgray;
    border-bottom-style: solid;
}
.chip {
  height: 75%;
  font-size: 75%;
}
/deep/div.v-input.v-input--selection-controls.v-input--checkbox {
  margin-top: 0px;
  padding-top: 0px;
}
/deep/ .payment-amount .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
  padding: 3px !important;
  width: 110px !important;
  height: 30px !important;
  margin-top: -5px !important;
}
/deep/ .payment-amount div.v-text-field__prefix {
  padding: 0 !important;
  margin: 0 !important;
  margin-right: 5px !important;
}
/deep/ .payment-amount div.v-text-field__slot input{
  padding: 0 !important;
  margin: 0!important;
}
/deep/.v-label.theme--light {
  color: black;
}
/deep/ .v-datatable > tbody > tr > td {
  line-height: 33px !important;
  height: 33px !important;
}
/deep/ .v-datatable > tfoot:nth-child(3) > tr:nth-child(1) {
  line-height: 33px !important;
  height: 33px !important;
}
/deep/ .v-datatable__actions {
  line-height: 33px !important;
  height: 33px !important;
}
/deep/ .v-datatable__actions__range-controls {
  position: relative !important;
  top: 10px !important;
}
</style>