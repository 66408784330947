<template>
    <v-dialog v-model="dialog" height="1000px" width="1000px">
        <the-loading ref="loading" />
        <v-card>
            <v-card-title class="text-xs-right">
                <v-spacer />
                <v-btn icon @click="close"><v-icon color="grey">fa-times</v-icon></v-btn>
            </v-card-title>
            <iframe v-if="showIframe" id="iframe" height="500px" width="600px"  frameborder="0"></iframe>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from "axios"
import TheLoading from "@/components/TheLoading.vue"
import Bus from '../../bus'

export default {
    components: {
        TheLoading
    },
    props: {
        customer: String,
        clientToken: String,
        invoiceToken: String,
        myAccountAppUrl: String,
        invoiceNumber: String,
        invoiceAmount: String
    },
    data() {
        return {
            dialog: false,
            showIframe: false,
            postingPayment: false
        }
    },
    methods: {
        async open(url) {
            this.dialog = true
            this.$refs.loading.loading(true)
            await (this.showIframe = true)
            var iframe = document.getElementById('iframe')
            await iframe.setAttribute("src", url)

            window.addEventListener('message', (ev) => {
                if (ev.data.type === 'stripe-3ds-result') {
                    if (ev.data.message.payment_intent && ev.data.message.payment_intent.status === 'succeeded') {
                        this.postPayment(ev.data.message.payment_intent.id)
                    } else if (ev.data.message.error) {
                        this.dialog = false
                        Bus.$emit('alert', {
                            color: 'error',
                            message: 'Payment authorization failed, please try again.'
                        })
                        this.$emit('getIdempotencyToken')
                    }
                }
            }, false)
            this.$refs.loading.loading(false)
        },
        close() {
            this.dialog = false
            this.showIframe = false
        },
        async postPayment(paymentIntentId) {
            if (!this.postingPayment) {
                this.postingPayment = true
                this.$refs.loading.loading(true)
                let payment = {
                    PayGateToken: this.$props.clientToken,
                    BAN: this.$props.customer,
                    InvoiceNumber: this.$props.invoiceNumber,
                    Amount: this.$props.invoiceAmount,
                    PaymentIntentId: paymentIntentId
                }
                await axios
                    .post(process.env.VUE_APP_PGAPI + "api/card/post-payment", payment)
                    .then(res => {
                        if (res.status == 200) {
                            this.postingPayment = false
                            this.dialog = false
                            Bus.$emit('alert', {
                                color: 'success',
                                message: 'Payment processed successfully!'
                            })
                            location.assign(`${this.$props.myAccountAppUrl}/billing-history?token=${this.$props.invoiceToken}&status=success`)
                        }
                    })
                    .catch((error) =>{
                        console.log(error);
                        this.postingPayment = false
                        this.dialog = false
                        Bus.$emit('alert', {
                            color: 'error',
                            message: 'Payment authorization failed, please try again.'
                        })
                        this.$emit('getIdempotencyToken')
                    });
                this.$refs.loading.loading(false)
            }
        }
    }
}
</script>