<template>
  <div>
    <v-btn icon :disabled="isFirstPageDisabled" @click="goToFirstPage">
      <v-icon>fa-angle-double-left</v-icon>
    </v-btn>
    <v-btn icon :disabled="isBackPageDisabled" @click="goToBackPage">
      <v-icon>fa-chevron-left</v-icon>
    </v-btn>
    <v-btn icon :disabled="isNextPageDisabled" @click="goToNextPage">
      <v-icon>fa-chevron-right</v-icon>
    </v-btn>
    <v-btn icon :disabled="isLastPageDisabled" @click="goToLastPage">
      <v-icon>fa-angle-double-right</v-icon>
    </v-btn>
  </div>
</template>
<script>
  export default {
    name: 'DataTablePagination',
    props: {
      value: {
        type: Number,
        default: null
      },
      rowsPerPage: {
        type: Number,
        default: null
      },
      totalRecords: {
        default: null
      }
    },
    data: () => ({
      page: null
    }),
    computed: {
      isFirstPageDisabled () {
        if (this.value) {
          if (this.value == 1) {
            return true
          } else {
            return false
          }
        } else {
          return true
        }
      },
      isBackPageDisabled () {
        if (this.isFirstPageDisabled == true) {
          return true
        } else {
          return false
        }
      },
      isNextPageDisabled () {
        if (this.isLastPageDisabled == true) {
          return true
        } else {
          return false
        }
      },
      isLastPageDisabled () {
        if (this.value && this.lastPage) {
          if (this.value >= this.lastPage) {
            return true
          } else {
            return false
          }
        } else {
          return true
        }
      },
      lastPage () {
        if (this.rowsPerPage && this.totalRecords) {
          if (this.rowsPerPage > 0) {
            return Math.ceil(this.totalRecords / this.rowsPerPage)
          } else {
            return 1
          }
        } else {
          return 1
        }
      }
    },
    methods: {
      goToFirstPage () {
        this.page = 1
      },
      goToBackPage () {
        if (this.value) {
          this.page -= 1
        }
      },
      goToNextPage () {
        if (this.value) {
          this.page += 1
        }
      },
      goToLastPage () {
        if (this.value && this.lastPage) {
          this.page = this.lastPage
        }
      }
    },
    watch: {
      page (val) {
        if (val) {
          this.$emit('input', val)
        }
      }
    },
    mounted () {
      if (this.value) {
        this.page = this.value
      }
    },
    updated () {
      if (this.value) {
        this.page = this.value
      }
    }
  }
</script>
<style scoped>
/deep/ .v-btn {
  height: 30px !important;
}
</style>
