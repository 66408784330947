<template>
    <v-container pa-0 mt-4>
        <the-loading ref="loading" />
        <v-layout row>
            <v-flex xs3.5 />
            <v-flex xs5>
                <stripe-element-card
                    v-if="publishableKey"
                    ref="elementRef"
                    :pk="publishableKey"
                    @token="tokenCreated"
                    :hidePostalCode=true
                />
            </v-flex>
            <v-flex xs3.5 />
        </v-layout>
        <stripe-confirmation-dialog
            ref="confirmationDialog"
            :customer="customer"
            :clientToken="clientToken"
            :myAccountAppUrl="myAccountAppUrl"
            :invoiceToken="invoiceToken"
            :invoiceNumber="invoiceNumber"
            :invoiceAmount="invoiceAmount"
            @getIdempotencyToken="getIdempotencyToken"
        />
    </v-container>
</template>

<script>
import axios from "axios"
import { StripeElementCard } from '@vue-stripe/vue-stripe'
import StripeConfirmationDialog from "./StripeConfirmationDialog.vue"
import TheLoading from "@/components/TheLoading.vue"
import Bus from '../../bus'

export default {
    components: {
        StripeElementCard,
        StripeConfirmationDialog,
        TheLoading
    },
    props: {
        customer: String,
        clientToken: String,
        firstName: String,
        lastName: String,
        email: String,
        invoiceToken: String,
        myAccountAppUrl: String,
        vizibillAppUrl: String,
        invoiceNumber: String,
        invoiceAmount: Number,
        invoices: Array,
        defaultPayment: Boolean
    },
    data() {
        return {
            token: null,
            publishableKey: null,
            idempotencyToken: '',
            formType: '',
            showIframe: false
        };
    },
    async mounted() {
        await this.getPubKey()
        this.getIdempotencyToken()
    },
    methods: {
        async submit(formType){
            this.$refs.loading.processing(true)
            this.formType = formType
            await this.$refs.elementRef.submit()
            this.$refs.loading.processing(false)
        },
        async tokenCreated (token) {
            this.$refs.loading.processing(true)
            this.token = token
            let card = {
                BAN: this.$props.customer,
                payGateToken: this.$props.clientToken,
                card: this.token.id, 
                ccDetails: {
                    ccNumber: this.token.card.last4
                },
                email: this.$props.email,
                name: `${this.$props.firstName} ${this.$props.lastName}`,
                idempotencyToken: this.idempotencyToken,
                Amount: this.$props.invoiceAmount,
                Currency: 'usd',
                DefaultPayment: this.$props.defaultPayment,
                UserName: 'myaccount@vizibill.com'
            }
            var url
            if (this.formType === 'addCard') {
                await axios.post(process.env.VUE_APP_PGAPI + "api/card/post", card)
                    .then(res => {
                        Bus.$emit('alert', {
                            color: 'success',
                            message: 'Card details saved successfully!'
                        })
                        location.assign(`${this.$props.myAccountAppUrl}?token=${this.$props.invoiceToken}`)
                    })
                    .catch((error) =>{
                        Bus.$emit('alert', {
                            color: 'error',
                            message: `There was an error saving your card details, please try again.`
                        })
                        this.getIdempotencyToken()
                    });
            } else if (this.formType === 'PayNow') {
                card.InvoiceNumber = this.$props.invoiceNumber
                card.InvoiceQuantity = "Single"
                await axios.post(process.env.VUE_APP_PGAPI + "api/card/payment", card)
                    .then(res => {
                        if (res.data.next_action != null) {
                            url = res.data.next_action.use_stripe_sdk.stripe_js
                            this.$refs.confirmationDialog.open(url)
                        } else if (res.status == 200) {
                            Bus.$emit('alert', {
                                color: 'success',
                                message: 'Payment processed successfully!'
                            })
                            location.assign(`${this.$props.myAccountAppUrl}/billing-history?token=${this.$props.invoiceToken}&status=success`)
                        }
                    })
                    .catch((error) =>{
                        Bus.$emit('alert', {
                            color: 'error',
                            message: `There was an error processing your payment, please try again.`
                        })
                        this.getIdempotencyToken()
                    });
            } else if (this.formType === 'PayAll') {
                card.Invoices = this.$props.invoices
                card.InvoiceQuantity = "Multiple"
                await axios.post(process.env.VUE_APP_PGAPI + "api/card/pay-all", card)
                    .then(res => {
                        if (res.data.next_action != null) {
                            url = res.data.next_action.use_stripe_sdk.stripe_js
                            this.$refs.confirmationDialog.open(url)
                        } else if (res.status == 200) {
                            Bus.$emit('alert', {
                                color: 'success',
                                message: 'Payment processed successfully!'
                            })
                            if (this.props.myAccountAppUrl) {
                                location.assign(`${this.$props.myAccountAppUrl}/billing-history?token=${this.$props.invoiceToken}&status=success`)
                            } else if (this.props.vizibillAppUrl) {
                                location.assign(`${this.$props.vizibillAppUrl}/account`)
                            }
                        }
                    })
                    .catch((error) =>{
                        Bus.$emit('alert', {
                            color: 'error',
                            message: `There was an error processing your payment, please try again.`
                        })
                        this.getIdempotencyToken()
                    });
            }
            this.$refs.loading.processing(false)
        },
        async getPubKey() {
            let pubKey = await axios.post(process.env.VUE_APP_PGAPI + "api/values/stripepubkey", {PayGateToken: this.$props.clientToken})
            this.publishableKey = pubKey.data
        },
        getIdempotencyToken() {
            this.idempotencyToken = self.crypto.randomUUID()
        }
    }
}
</script>
<style scoped>
/* /deep/ div#stripe-element-mount-point.StripeElement {
    border-bottom: 2px solid #949494 !important;
    border-radius: 4px 4px 0px 0px !important;
    background-color: #f0f0f0 !important;
    box-shadow: none !important;
} */
</style>