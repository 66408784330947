<template>
  <v-app class="app">
    <the-loading ref="loading" />
    <my-account-40-header :account="account" :firstName="firstName" :lastName="lastName" :clientName="clientName" :myAccountAppUrl="myAccountAppUrl" :invoiceToken="invoiceToken" :myAccountToken="myAccountToken" :showACH="showACH" class="add-card-header"/>
    <v-container class="content" fluid px-4 pb-4 pt-0 fill-height>
      <v-flex align-self-start>
        <v-layout row fill-height class="justify-center text-xs-left">
          <v-spacer></v-spacer>
          <v-flex xs9>
            <v-card flat>
              <v-flex xs12 class="justify-center text-xs-center">
                <h1>Pay All {{ isParent ? '(including children)' : '' }}</h1>
              </v-flex>
              <v-card-text mt-2 v-if="showForm" style="height:100%">
                <v-data-table
                  :headers="headers"
                  :items="openInvoices"
                  :pagination.sync="pagination"
                  :rows-per-page-items="[5]"
                  select-all
                  item-key="DocId"
                >
                  <template v-slot:headers="props">
                    <tr>
                      <th>
                        <v-checkbox
                          :input-value="props.all"
                          :indeterminate="props.indeterminate"
                          v-model="selectAll"
                          primary
                          hide-details
                          @click.stop="toggleAll"
                        ></v-checkbox>
                      </th>
                      <th v-if="isParent"></th>
                      <th
                        v-for="header in props.headers"
                        :key="header.text"
                        :class="header.class"
                      >{{ header.text }}</th>
                    </tr>
                  </template>
                  <template v-slot:items="props">
                    <td class="text-md-left">
                      <v-checkbox
                        v-model="selected"
                        primary
                        hide-details
                        :value="props.item"
                      ></v-checkbox>
                    </td>
                    <td v-if="isParent">
                      <v-chip 
                        class="chip"
                        small
                        :color="props.item.AccountBAN == BAN ? '#dfdfff' : '#fef0df'"
                        :text-color="props.item.AccountBAN == BAN ? '#0000ff' : '#fb8c00'" 
                      >{{ props.item.AccountBAN == BAN ? 'Parent' : 'Child' }}</v-chip>
                    </td>
                    <td class="text-md-left">{{ props.item.DocId }}</td>
                    <td v-if="isParent" class="text-md-left">{{ props.item.AccountBAN }}</td>
                    <td class="text-md-left">{{ formatDate(props.item.Date) }}</td>
                    <td class="text-md-right">{{ formatAmount(props.item.Balance) }}</td>
                  </template>
                  <template v-slot:actions-append>
                    <span style="color: black; position: relative; top: 10px !important; margin-left: 140px; margin-right: 23px;"><b>Selected Total:</b> {{ totalAmount }}</span>
                  </template>
                  <template v-slot:no-data>
                    <td colspan="4" class="text-md-center"><span color="success">No pending invoices on this account.</span></td>
                  </template>
                </v-data-table>
                <v-layout row>
                  <v-flex xs12 mb-4 mt-1 class="justify-center text-xs-center"><v-divider /></v-flex>
                </v-layout>
                <v-layout row mt-2>
                  <v-flex xs6 mr-2 class="justify-center text-xs-right"><b>Total Payment:</b></v-flex>
                  <v-flex xs1 class="justify-center text-xs-left">
                    <v-text-field
                      class="payment-amount"
                      v-model="paymentAmount"
                      outline
                      hide-details
                      prefix="$"
                    />
                  </v-flex>
                  <v-flex xs5></v-flex>
                </v-layout>
                <v-layout row>
                  <v-flex xs12 mx-2 mt-3 class="justify-center text-xs-center">Please select a payment option.</v-flex>
                </v-layout>
                <v-layout row>
                  <v-radio-group class="justify-center text-xs-center mb-0" v-model="paymentMethod" row height="0px">
                    <v-radio label="Credit Card" value="ST"></v-radio>
                    <v-radio label="ACH" value="AC"></v-radio>
                  </v-radio-group>
                </v-layout>
                <add-stripe-form
                  ref="stripeForm"
                  v-if="paymentMethod === 'ST'"
                  :customer="BAN" 
                  :clientToken="clientToken"
                  :firstName="firstName"
                  :lastName="lastName"
                  :email="emailAddress"
                  :myAccountAppUrl="myAccountAppUrl"
                  :invoiceToken="invoiceToken"
                  :invoices="selected"
                  :invoiceAmount="paymentAmount"
                  :defaultPayment="defaultPayment"
                />
                <ach-payment-form
                  ref="achForm"
                  v-if="paymentMethod === 'AC'"
                  :customer="BAN" 
                  :clientToken="clientToken"
                  :firstName="firstName"
                  :lastName="lastName"
                  :email="emailAddress"
                  :myAccountAppUrl="myAccountAppUrl"
                  :invoiceToken="invoiceToken"
                  :invoices="selected"
                  :invoiceAmount="paymentAmount"
                  :defaultPayment="defaultPayment"
                />
                <v-checkbox size="large" class="justify-center text-xs-center pt-4" v-model="defaultPayment" :label="checkboxLabel" />
                <p class="justify-center text-xs-center mt-4 mb-4">By clicking <b>Pay Now</b> you agree to the <router-link to="/termsconditions">Terms & Conditions</router-link> and <router-link to="/privacypolicy">Privacy Policy.</router-link></p>
                <v-flex class="justify-center text-xs-center">
                  <a @click="cancel">Cancel</a>
                  <v-btn class="ml-4" large color="success" @click="submitPayment">Pay Now</v-btn>
                </v-flex>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-spacer></v-spacer>
        </v-layout>
      </v-flex>
    </v-container>
    <my-account-40-footer class="add-card-footer"></my-account-40-footer>
  </v-app>
</template>

<script>
import axios from "axios"
import store from "../../store"
import dayjs from 'dayjs'
import MyAccount40Footer from "@/components/MyAccount40/MyAccount40Footer.vue"
import MyAccount40Header from "@/components/MyAccount40/MyAccount40Header.vue"
import AddStripeForm from "@/components/MyAccount40/MyAccountStripeForm.vue"
import AchPaymentForm from "@/components/MyAccount40/MyAccountACHPaymentForm.vue"
import TheLoading from "@/components/TheLoading.vue"

export default {
  components: {
    /* eslint-disable vue/no-unused-components */
    MyAccount40Footer,
    MyAccount40Header,
    AddStripeForm,
    AchPaymentForm,
    TheLoading
  },
  data: () => ({
    valid: false,
    menu: false,
    BAN: "",
    myAccountToken: "",
    invoiceToken: "",
    paymentAmount: null,
    last4: "",
    expiry: "",
    clientToken: "",
    clientName: "",
    clientSecret: "",
    myAccountAppUrl: "",
    paymentProvider: "",
    account: {},
    firstName: "",
    lastName: "",
    emailAddress: "",
    defaultPayment: false,
    showForm: false,
    showACH: false,
    paymentMethod: 'ST',
    isParent: false,
    selected: [],
    openInvoices: [],
    invoices: [],
    
    pagination: { rowsPerPage: 5 },
    headers: [
      {
        text: "Invoice Number",
        value: "invoiceNumber",
        class: 'font-weight-bold subheading'
      },
      {
        text: "Account Number",
        value: "accountNumber",
        class: 'font-weight-bold subheading'
      },
      {
        text: "Date",
        value: "invoiceDate",
        class: 'font-weight-bold subheading text-xs-left'
      },
      {
        text: "Balance",
        value: "invoiceAmount",
        class: 'font-weight-bold subheading text-xs-right'
      }
    ],
  }),
  async mounted() {
    this.$refs.loading.loading(true)
    this.myAccountToken = this.$route.query.token
    // this.deleteQueryString("token")
    this.invoiceToken = this.$route.query.invoicetoken
    // this.deleteQueryString("invoicetoken")
    await this.decodeMyAccountToken()
    await this.getBoss40AccountDetails()
    await this.getMyAccountUrls()
    await this.checkBusinessRules()
    this.showForm = true
    this.$refs.loading.loading(false)
  },
  watch: {
    totalAmount() {
      this.paymentAmount = this.totalAmount.replace('$', '')
    }
  },
  computed: {
    checkboxLabel() {
      if (this.paymentMethod == 'ST') {
        return 'Sign up for autopay using this credit card.'
      } else {
        return 'Sign up for autopay using this bank account.'
      }
    },
    totalAmount() {
      return this.formatAmount(this.selected.reduce((acc, cur) => acc + parseFloat(cur.Balance), 0).toFixed(2))
    },
    selectAll() {
      if (this.selected.length === this.openInvoices.length) return true
      else return false
    }
  },
  methods: {
    async submitPayment() {
      let payment = parseFloat(this.paymentAmount.replace(',', '').replace('$', ''))
      let total = parseFloat(this.totalAmount.replace(',', '').replace('$', ''))
      if (payment != total) {
        let diff = payment - total
        if (!confirm(`The total payment does not match the invoice balance. Are you sure you want to ${diff > 0 ? 'overpay' : 'underpay'} this invoice by $${Math.abs(diff).toFixed(2)}?`)) return
      }
      if (this.defaultPayment) {
        this.account.paymentMethodId = this.paymentMethod
        await axios.post(process.env.VUE_APP_PGAPI + 'api/boss40/editaccount', { "account": this.account, "payGateToken": this.clientToken })
      }
      if (this.paymentMethod == 'ST') {
        await this.$refs.stripeForm.submit('PayAll')
      } else {
        await this.$refs.achForm.submit('PayAll')
      }
    },
    async decodeMyAccountToken(){
      await axios
        .post(process.env.VUE_APP_PGAPI + `api/boss40/DecodeMyAccountToken/?token=${this.myAccountToken}`)
        .then((resp) => {
          this.clientToken = resp.data.PaygateToken
          this.BAN = resp.data.AccountNumber
          store.state.BAN = resp.data
          this.clientName = resp.data.Client
          this.emailAddress = resp.data.Email
          this.firstName = resp.data.FirstName
          this.lastName = resp.data.LastName
          this.paymentProvider = resp.data.PaymentProvider
          this.invoiceNumber = resp.data.InvoiceNumber
          this.invoiceDate = resp.data.InvoiceDate
          this.openInvoices = resp.data.OpenInvoices
          this.isParent = resp.data.IsParent
        })
        this.selected = this.openInvoices
        if (!this.isParent) this.headers.splice(1, 1)
    },
    async getBoss40AccountDetails(){
      await axios
        .post(process.env.VUE_APP_PGAPI + "api/boss40/getaccount", {"accountNumber" : this.BAN, "payGateToken" : this.clientToken })
        .then((resp) => {
          this.account = resp.data;
          store.state.account = resp.data
        });
    },
    async getMyAccountUrls() {
      await axios
      .post(process.env.VUE_APP_PGAPI + "api/config/getmyaccounturls", { clientToken: this.clientToken })
      .then((resp) => {
        this.myAccountAppUrl = resp.data.MyAccountAppUrl
        this.clientName = resp.data.ClientName
      })
    },
    async checkBusinessRules() {
      await axios
        .post(process.env.VUE_APP_PGAPI + 'api/boss40/getbusinessrules', { payGateToken: this.clientToken, Filters: { Rules: ["MYACH"] } })
        .then((res) => {
          if (res.data) {
            let achRule = JSON.parse(res.data).find(a => a.Id == "MYACH")
            if (achRule.Value === 'TRUE') this.showACH = true
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    cancel() {
      location.assign(`${this.myAccountAppUrl}/billing-history?token=${this.invoiceToken}`)
    },
    deleteQueryString(queryString) {
      let query = Object.assign({}, this.$route.query);
      delete query[`${queryString}`];
      this.$router.replace({ query });
    },
    formatDate(date) {
      if (date) {
        let YYYYMMDD = dayjs(date, 'YYYY-MM-DD')
        return YYYYMMDD.format('DD MMM, YYYY')
      }
    },
    formatAmount(value) {
      var absValue = Math.abs(value)
      var returnString = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      }).format(absValue)
      return value < 0 ? '-' + returnString : returnString
    },
    toggleAll () {
      if (this.selected.length) this.selected = []
      else this.selected = this.openInvoices.slice()
    },
  },
};
</script>

<style scoped>
.app {
  width: 100%;
  background-color: #EEEEEE;
}
.add-card-header {
  min-height: 100px;
  background-color: white;
  min-width: 1133px;
  width: 60%;
  margin: 0 auto;
}
.add-card-footer {
  position: relative !important;
  min-width: 1133px !important;
  width: 60% !important;
  margin: 0 auto;
}
.page-title {
  font-size: x-large;
  font-weight: bold;
}
.content {
  position: relative;
  min-width: 1133px;
  width: 60%;
  margin: 0 auto;
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: white;
}
.whitebg {
  background-color: white;
}
.action-bars {
  background-color: #f0f0f0 !important;
  min-height: 52px;
  max-height: 52px;
}
.card-title{
    font-family: Roboto, sans-serif;
    font-size: 20px;
}
.form-field-label{
    color: grey;
    font-family: Roboto, sans-serif;
    font-size: 12px;
}
.form-field{
    border-bottom-width: 1px;
    border-bottom-color: lightgray;
    border-bottom-style: solid;
}
.chip {
  height: 75%;
  font-size: 75%;
}
/deep/div.v-input.v-input--selection-controls.v-input--checkbox {
  margin-top: 0px;
  padding-top: 0px;
}
/deep/ .payment-amount .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
  padding: 3px !important;
  width: 110px !important;
  height: 30px !important;
  margin-top: -5px !important;
}
/deep/ .payment-amount div.v-text-field__prefix {
  padding: 0 !important;
  margin: 0 !important;
  margin-right: 5px !important;
}
/deep/ .payment-amount div.v-text-field__slot input{
  padding: 0 !important;
  margin: 0!important;
}
/deep/.v-label.theme--light {
  color: black;
}
/deep/ .v-datatable > tbody > tr > td {
  line-height: 33px !important;
  height: 33px !important;
}
/deep/ .v-datatable > tfoot:nth-child(3) > tr:nth-child(1) {
  line-height: 33px !important;
  height: 33px !important;
}
/deep/ .v-datatable__actions {
  line-height: 33px !important;
  height: 33px !important;
}
/deep/ .v-datatable__actions__range-controls {
  position: relative !important;
  top: 10px !important;
}
</style>