<template>
  <v-toolbar class="header" height="100px" flat>
    <v-flex xs12>
      <v-layout mb-2 row>
        <v-flex xs3 class="text-xs-left">
          <v-img
            :src="logoPath"
            cover
            min-height="40"
            max-height="70"
            min-width="90"
            max-width="150"
          ></v-img>
        </v-flex>
        <v-flex mt-2 xs6>
          <p class="title">My Account #{{account.AccountNumber}}</p>
        </v-flex>
        <v-flex xs3>
          <v-toolbar-title class="text-xs-right">
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-chip v-on="on" color="#e2f0fb" text-color="#3091e6" class="user-chip"><v-icon small class="ml-1 mr-2">fa-user</v-icon>{{ firstName }} {{ formatLastName(lastName) }}</v-chip>
              </template>
              <v-list class="overflow-hidden">
                <v-list-tile v-if="hasLinkedAccounts" class="px-6" height="60" @click="addAccount">
                  <v-icon class="mr-2">fa-user-plus</v-icon><v-list-tile-title>ADD ACCOUNT</v-list-tile-title>
                </v-list-tile>
                <v-list-tile class="px-6" height="60" @click="myProfile">
                  <v-icon class="mr-2">fa-user</v-icon><v-list-tile-title>MY PROFILE</v-list-tile-title>
                </v-list-tile>
                <v-list-tile class="px-6" height="60" @click="addCard">
                  <v-icon class="mr-2">fa-credit-card</v-icon><v-list-tile-title>ADD CARD</v-list-tile-title>
                </v-list-tile>
                <v-list-tile v-if="showACH" class="px-6" height="60" @click="addACH">
                  <v-icon class="mr-2">fa-university</v-icon><v-list-tile-title>ADD BANK DETAILS</v-list-tile-title>
                </v-list-tile>
                <v-list-tile class="px-6" height="60" @click="logout">
                  <v-icon class="mr-2">fa-sign-out-alt</v-icon><v-list-tile-title>LOGOUT</v-list-tile-title>
                </v-list-tile>
              </v-list>
            </v-menu>
          </v-toolbar-title>
        </v-flex>
      </v-layout>
      <v-divider pt-2 />
    </v-flex>
  </v-toolbar>
</template>

<script>
import store from "../../store";

export default {
  name: "myaccount40-header",
  props: [
    "account",
    "firstName",
    "lastName",
    "clientName",
    "myAccountAppUrl",
    "myAccountToken",
    "invoiceToken",
    "showACH",
    "hasLinkedAccounts",
  ],
  data() {
    return {}
  },
  mounted() {
    this.init()
  },
  computed: {
    logoPath() {
      switch(this.$props.clientName) {
        case "DMP":
          return require('@/assets/img/dmpLogo.png')
        case "Atlas":
          return require('@/assets/img/atlasLogo.png')
        case "Securecom":
          return require('@/assets/img/securecomLogo.png')
        default:
          return require('@/assets/img/vizibillLogo.png')
      }
    }
  },
  methods: {
    init() {
      this.account = store.state.account
    },
    formatLastName (lastName) {
      let lastInitial = `${lastName.charAt(0).toUpperCase()}.`
      return lastInitial
    },
    myProfile () {
      location.assign(`${this.$props.myAccountAppUrl}/my-profile?token=${this.$props.invoiceToken}`)
    },
    addCard () {
      this.$router.push(`/myaccount40/addcard?token=${this.$props.myAccountToken}&invoicetoken=${this.$props.invoiceToken}`)
    },
    addACH () {
      this.$router.push(`/myaccount40/addach?token=${this.$props.myAccountToken}&invoicetoken=${this.$props.invoiceToken}`)
    },
    addAccount () {
      location.assign(`${this.$props.myAccountAppUrl}?token=${this.$props.invoiceToken}&status=addaccount`)
    },
    logout () {
      location.assign(`${this.$props.myAccountAppUrl}/logout`)
    },
  },
};
</script>
<style scoped>
.title {
  font-size: 31px !important;
  font-weight: bold;
}
.header {
  font-family: Arial, Helvetica, sans-serif;
}
.user-chip {
  height: 40px;
  font-size: medium;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>