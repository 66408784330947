<template>
  <v-footer class="footer" height="60px" color="white" app>
    <v-flex xs12 px-3 pb-1>
      <v-divider />
      <v-layout row class="fill-height align-center py-2">
        <v-flex xs2 pl-2 class="text-xs-left">
          {{ year }} Copyright
        </v-flex>
        <v-flex xs8>
          <a target="_blank" href="#/privacypolicy">Privacy Policy</a><v-divider class="mx-2 pb-1" vertical style="display: inline;"/><a target="_blank" href="#/termsconditions">Terms & Conditions</a>
        </v-flex>
        <v-flex xs2 pr-2  class="text-xs-right">
          Powered by <a target="_blank" href="https://vizibill.com/">Vizibill</a>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-footer>
</template>

<script>
import moment from 'moment';
export default {
  name: 'myaccount40-footer',
  data() {
    return {}
  },
  computed: {
    year() {
      return moment().format('YYYY');
    },
  },
};
</script>
<style scoped>
a {
  text-decoration: none !important;
}
.footer {
  font-family: Arial, Helvetica, sans-serif;
  font-size: medium;
}
</style>
