<template>
  <v-app class="app">
    <the-loading ref="loading" />
    <my-account-40-header
      class="add-card-header"
      :account="account"
      :firstName="firstName"
      :lastName="lastName"
      :clientName="clientName"
      :myAccountAppUrl="myAccountAppUrl"
      :invoiceToken="invoiceToken"
      :myAccountToken="myAccountToken"
      :showACH="showACH"
      :hasLinkedAccounts="hasLinkedAccounts"
    />
    <v-container class="content" fluid pa-4 fill-height>
      <v-flex align-self-start>
        <v-layout row fill-height class="justify-center text-xs-center">
          <v-spacer></v-spacer>
          <v-flex xs8>
            <v-card flat>
              <v-flex xs12 class="justify-center text-xs-center">
                <h1>Add Bank Details</h1>
              </v-flex>
              <v-card-text style="height:100%">
                <v-form
                v-model="valid"
                @submit="submit"
                ref="saveACHForm"
                lazy-validation
                style="height:100%"
              >
                <v-layout row>
                  <v-flex xs6 mr-2>
                    <v-text-field
                      name="accountHolderName"
                      v-model="accountHolderName"
                      :rules="requiredRule"
                      box
                      background-color="#e8f0fe"
                      ><template slot="label">
                        Account Holder Name<red-asterisk></red-asterisk></template>
                    </v-text-field>
                  </v-flex>
                  <v-flex xs6 ml-2>
                    <v-select
                      :items="accountHolderTypes"
                      v-model="accountHolderType"
                      :rules="accountHolderTypeRule"
                      autocomplete
                      box
                      background-color="#e8f0fe"
                    ><template slot="label">Account Type<red-asterisk></red-asterisk></template></v-select>
                  </v-flex>
                </v-layout>
                <v-layout row>
                  <v-flex xs6 mr-2>
                    <v-text-field
                      name="bankAccountNumber"
                      v-model="bankAccountNumber"
                      :rules="accountNumberRule"
                      box
                      background-color="#e8f0fe"
                      ><template slot="label">
                        Account Number<red-asterisk></red-asterisk></template>
                    </v-text-field>
                  </v-flex>
                  <v-flex xs6 ml-2>
                    <v-text-field
                      name="bankRoutingNumber"
                      v-model="bankRoutingNumber"
                      :rules="routingNumberRule"
                      :mask="routingNumberMask"
                      box
                      background-color="#e8f0fe"
                      ><template slot="label">
                        Routing Number<red-asterisk></red-asterisk></template>
                    </v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row>
                  <v-flex xs6 mr-2>
                    <v-text-field
                      name="email"
                      v-model="email"
                      :rules="emailRules"
                      box
                      background-color="#e8f0fe"
                      ><template slot="label">
                        Account Holder Email<red-asterisk></red-asterisk></template>
                    </v-text-field>
                  </v-flex>
                  <v-flex xs6 ml-2>
                    <v-text-field
                      name="phoneNumber"
                      v-model="phoneNumber"
                      :rules="requiredRule"
                      :mask="phoneNumberMask"
                      box
                      background-color="#e8f0fe"
                      ><template slot="label">
                        Phone Number<red-asterisk></red-asterisk></template>
                    </v-text-field>
                  </v-flex>
                </v-layout>
                </v-form>
                <v-checkbox size="large" class="justify-center text-xs-center pt-4" v-model="defaultPayment" label="Sign up for autopay using this bank account." />
                <p>By clicking <b>Save</b> you agree to the <router-link to="/termsconditions">Terms & Conditions</router-link> and <router-link to="/privacypolicy">Privacy Policy.</router-link></p>
                <v-flex>
                  <a @click="cancel">Cancel      </a>
                  <v-btn large color="info" @click="submit">Save</v-btn>
                </v-flex>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-spacer></v-spacer>
        </v-layout>
      </v-flex>
    </v-container>
    <my-account-40-footer class="add-card-footer"></my-account-40-footer>
  </v-app>
</template>

<script>
import axios from "axios"
import store from "../../store"
import MyAccount40Footer from "@/components/MyAccount40/MyAccount40Footer.vue"
import MyAccount40Header from "@/components/MyAccount40/MyAccount40Header.vue";
import RedAsterisk from "@/components/RedAsterisk.vue"
import TheLoading from "@/components/TheLoading.vue"
import Bus from '../../bus'

export default {
  components: {
    /* eslint-disable vue/no-unused-components */
    MyAccount40Footer,
    MyAccount40Header,
    RedAsterisk,
    TheLoading
  },
  data: () => ({
    valid: false,
    menu: false,
    BAN: "",
    myAccountToken: "",
    invoiceToken: "",
    clientToken: "",
    clientName: "",
    clientSecret: "",
    myAccountAppUrl: "",
    paymentProvider: "",
    defaultPayment: false,
    showACH: false,
    hasLinkedAccounts: true,
    account: {},
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    accountHolderName: "",
    accountHolderType: "Please Select",
    bankName: "",
    bankAccountNumber: "",
    bankRoutingNumber: "",
    accountHolderTypes: [
      {value: "Please Select", text: "Please Select"},
      {value: "BUSINESS_CHECKING", text: "Business Checking"},
      {value: "BUSINESS_SAVINGS", text: "Business Savings"},
      {value: "PERSONAL_CHECKING", text: "Personal Checking"},
      {value: "PERSONAL_SAVINGS", text: "Personal Savings"}
    ],
    phoneNumberMask: "(###) ###-####",
    routingNumberMask: "#########",
    accountHolderTypeRule: [(v) => v != "Please Select" || "Required"],
    emailRules: [
      (v) => !!v || "Required",
      (v) =>
        /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) || "E-mail must be valid",
    ],
    accountNumberRule: [(v) => v.length > 5 && v.length < 18 || "Account Number must be 6 to 17 digits long."],
    routingNumberRule: [(v) => v.length == 9 || "Routing Number must be 9 digits."],
    requiredRule: [(v) => !!v || "Required"],
  }),
  async mounted() {
    this.$refs.loading.loading(true)
    this.myAccountToken = this.$route.query.token
    // this.deleteQueryString("token")
    this.invoiceToken = this.$route.query.invoicetoken
    // this.deleteQueryString("invoicetoken")
    await this.decodeMyAccountToken()
    await this.getBoss40AccountDetails()
    await this.getMyAccountUrls()
    await this.checkBusinessRules()
    this.$refs.loading.loading(false)
  },
  methods: {
    async submit() {
      this.$refs.loading.processing(true)
      if (this.$refs.saveACHForm.validate()) {
        if (this.defaultPayment) {
          this.account.paymentMethodId = "AC"
          await axios.post(process.env.VUE_APP_PGAPI + 'api/boss40/editaccount', { "account": this.account, "payGateToken": this.clientToken })
        }
        await axios.post(process.env.VUE_APP_PGAPI + "api/ach/create" , 
        { 
          payGateToken: this.clientToken,
          BAN: this.BAN,
          email: this.email,
          quickbooksACH: {
            phone: this.phoneNumber,
            name: this.accountHolderName,
            accountType: this.accountHolderType,
            accountNumber: this.bankAccountNumber,
            routingNumber: this.bankRoutingNumber,
            default: this.defaultPayment
          }
        })
        .then(res => {
            if (res.status == 200) {
              Bus.$emit('alert', {
                color: 'success',
                message: 'Bank details saved successfully!'
              })
              location.assign(`${this.myAccountAppUrl}?token=${this.invoiceToken}`)
            }
          })
        .catch((error) =>{
          Bus.$emit('alert', {
            color: 'error',
            message: `There was an error saving your bank details due to an invalid routing number.`
          })
          console.log(error)
        });
      }
      this.$refs.loading.processing(false)
    },
    async decodeMyAccountToken(){
      await axios
        .post(process.env.VUE_APP_PGAPI + `api/boss40/DecodeMyAccountToken/?token=${this.myAccountToken}`)
        .then((resp) => {
          this.clientToken = resp.data.PaygateToken
          this.BAN = resp.data.AccountNumber
          store.state.BAN = resp.data
          this.clientName = resp.data.Client
          this.ccPhoneNumber = resp.data.PhoneNumber
          this.ccEmailAddress = resp.data.Email
          this.firstName = resp.data.FirstName
          this.lastName = resp.data.LastName
          this.paymentProvider = resp.data.PaymentProvider
        })
    },
    async getBoss40AccountDetails(){
      await axios
        .post(process.env.VUE_APP_PGAPI + "api/boss40/getaccount", {"accountNumber" : this.BAN, "payGateToken" : this.clientToken })
        .then((resp) => {
          this.account = resp.data;
          store.state.account = resp.data
        });
    },
    async getMyAccountUrls() {
      await axios
      .post(process.env.VUE_APP_PGAPI + "api/config/getmyaccounturls", {
        clientToken: this.clientToken
      })
      .then((resp) => {
        this.myAccountAppUrl = resp.data.MyAccountAppUrl
        this.clientName = resp.data.ClientName
      })
    },
    async checkBusinessRules() {
      await axios
        .post(process.env.VUE_APP_PGAPI + 'api/boss40/getbusinessrules', { payGateToken: this.clientToken, Filters: { Rules: ["MYACH"] } })
        .then((res) => {
          if (res.data) {
            let achRule = JSON.parse(res.data).find(a => a.Id == "MYACH")
            if (achRule.Value === 'TRUE') this.showACH = true
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    cancel() {
      location.assign(`${this.myAccountAppUrl}?token=${this.invoiceToken}`)
    },
    deleteQueryString(queryString) {
      let query = Object.assign({}, this.$route.query);
      delete query[`${queryString}`];
      this.$router.replace({ query });
    }
  },
};
</script>

<style scoped>
.app {
  width: 100%;
  background-color: #EEEEEE;
}
.add-card-header {
  min-height: 100px;
  background-color: white;
  min-width: 1133px;
  width: 60%;
  margin: 0 auto;
}
.add-card-footer {
  position: relative !important;
  min-width: 1133px !important;
  width: 60% !important;
  margin: 0 auto;
}
.page-title {
  font-size: x-large;
  font-weight: bold;
}
.content {
  position: relative;
  min-width: 1133px;
  width: 60%;
  margin: 0 auto;
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: white;
}
.whitebg {
  background-color: white;
}
.action-bars {
  background-color: #f0f0f0 !important;
  min-height: 52px;
  max-height: 52px;
}
.card-title{
    font-family: Roboto, sans-serif;
    font-size: 20px;
}
.form-field-label{
    color: grey;
    font-family: Roboto, sans-serif;
    font-size: 12px;
}
.form-field{
    border-bottom-width: 1px;
    border-bottom-color: lightgray;
    border-bottom-style: solid;
}
/deep/div.v-input.v-input--selection-controls.v-input--checkbox {
  margin-top: 0px;
  padding-top: 0px;
}
/deep/.v-text-field .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}
/deep/.v-label.theme--light {
  color: black;
}
</style>