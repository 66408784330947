<template>
  <v-container fluid px-4 pt-4 fill-height>
      <the-loading ref="loading" />
      <v-flex align-self-start>
        <v-card>
          <v-flex xs12 pt-3 px-3 class="justify-start text-xs-left">
            <h2>Children Invoices</h2>
          </v-flex>
          <v-card-text class="table-card" mt-2 style="height:100%">
            <v-data-table
              :headers="headers"
              :items="childInvoices"
              item-key="DocId"
            >
              <template v-slot:headers="props">
                <tr>
                  <th
                    v-for="header in props.headers"
                    :key="header.text"
                    :class="header.class"
                  >{{ header.text }}</th>
                </tr>
              </template>
              <template v-slot:items="props">
                <tr>
                  <td style="font-weight: bold;">{{ props.item.AccountBAN }}</td>
                  <td style="font-weight: bold;">{{ props.item.Description }}</td>
                  <td class="text-xs-center"><v-chip
                    class="white-chip"
                    :color="getTypeColour(props.item.TypeDescription)"
                    size="small"
                    >{{ props.item.TypeDescription }}</v-chip></td>
                  <td>{{ formatDate(props.item.Date) }}</td>
                  <td class="text-xs-center"><v-chip class="white-chip" size="small" :color="props.item.StatusColor">{{ props.item.StatusDetails }}</v-chip></td>
                  <td style="font-weight: bold;" :class="`text-${account.Amount >= 0 ? 'default' : 'success'} text-xs-right`">{{ formatAmount(props.item.Amount) }}</td>
                  <td :class="`text-${props.item.Balance >= 0 ? 'default' : 'success'} text-xs-right`">
                    {{ formatAmount(props.item.Balance) }}
                  </td>
                  <td class="text-xs-right" pr-0 style="width: 240px;">
                    <v-layout :class="props.item.Balance > 0 ? 'ma-0 pa-0' : ''">
                      <v-flex xs12 :class="props.item.Balance > 0 ? 'ma-0 px-0 pb-0 pt-2' : ''">
                        <v-btn
                          v-if="props.item.Balance > 0"
                          class="SolidButton"
                          @click="openPayNow(props.item)"
                        >Pay Now</v-btn>
                      </v-flex>
                    </v-layout>
                  </td>
                </tr>
              </template>
              <template v-slot:actions-append>
                <v-layout class="actions-append">
                  <v-spacer></v-spacer>
                  <v-flex xs1 class="total-amount text-xs-right" v-if="childInvoices.length > 0">
                    {{ formatAmount(totalAmount) }}
                  </v-flex>
                  <v-flex xs3 pl-2 ml-3>
                    <v-btn
                      v-if="totalAmount > 0"
                      class="SolidButton"
                      @click="openPayAll()"
                    >Pay All Children Only</v-btn>
                  </v-flex>
                  <v-flex xs1></v-flex>
                </v-layout>
              </template>
              <template v-slot:no-data>
                <td colspan="4" class="text-md-center"><span color="success">No invoices for this account.</span></td>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-container>
</template>

<script>
import axios from "axios"
import dayjs from 'dayjs'
import store from "../../store"
import TheLoading from "@/components/TheLoading.vue"

export default {
  components: {
    /* eslint-disable vue/no-unused-components */
    TheLoading
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    clientToken: {
      type: String,
      required: true
    },
    accountToken: {
      type: String,
      required: true
    },
    BAN: {
      type: String,
      required: true
    },
    parentName: {
      type: String,
      required: true
    },
    emailAddress: {
      type: String,
      required: true
    },
    userName: {
      type: String,
      required: true
    }
  },
  data: () => ({
    valid: false,
    menu: false,
    account: {},
    childInvoices: [],
    totalAmount: 0,
    
    headers: [
      {
        text: "Account Number",
        value: "AccountNumber",
        width: '10%',
        class: 'font-weight-bold subheading text-xs-left'
      },
      {
        text: "Description",
        value: "Description",
        width: '10%',
        class: 'font-weight-bold subheading text-xs-left'
      },
      {
        text: "Type",
        value: "Type",
        width: '10%',
        class: 'font-weight-bold subheading text-xs-center'
      },
      {
        text: "Date",
        value: "invoiceDate",
        width: '10%',
        class: 'font-weight-bold subheading text-xs-left'
      },
      {
        text: "Status",
        value: "Status",
        width: '10%',
        class: 'font-weight-bold subheading text-xs-center'
      },
      {
        text: "Original Amount",
        value: "OriginalAmount",
        width: '10%',
        class: 'font-weight-bold subheading text-xs-right'
      },
      {
        text: "Balance",
        value: "Balance",
        width: '10%',
        class: 'font-weight-bold subheading text-xs-right'
      },
      {
        text: "Actions",
        value: "Actions",
        width: '30%',
        class: 'font-weight-bold subheading text-xs-right'
      }
    ],
  }),
  async mounted() {
    this.setChildInvoices(this.item)
  },
  watch: {},
  computed: {},
  methods: {
    async setChildInvoices(item) {
      this.$refs.loading.loading(true)
      this.childInvoices = item.ChildInvoices.receivables
      this.totalAmount = item.ChildInvoices.totalBalance
      this.setStatusDetails()
      this.$refs.loading.loading(false)
    },
    openPayNow(item) {
      let params = {
        invoiceDetails: {...item},
        accountToken: this.accountToken,
        clientToken: this.clientToken,
        BAN: item.AccountBAN,
        emailAddress: this.emailAddress,
        userName: this.userName
      }
      store.commit('SET_ADMIN_PAY_CONSOLE_DETAILS', params)
      this.$router.push({
        name: 'AdminPayNow'
      })
    },
    async openPayAll() {
      let params = {
        invoiceDetails: this.childInvoices.filter((i) => i.Balance > 0),
        accountToken: this.accountToken,
        clientToken: this.clientToken,
        isParent: true,
        BAN: this.BAN,
        emailAddress: this.emailAddress,
        userName: this.userName,
        name: this.parentName
      }
      store.commit('SET_ADMIN_PAY_CONSOLE_DETAILS', params)
      this.$router.push({
        name: 'AdminPayAll'
      })
    },
    setStatusDetails() {
      this.childInvoices.forEach((item) => {
        switch (item.TypeDescription) {
          case 'Payment':
            switch (item.Status) {
              case 'C4':
              case 'C5':
              case 'C6':
                item.StatusDetails = 'Applied'
                item.StatusColor = 'success'
                break
              case 'A0':
                item.StatusDetails = 'Pending'
                item.StatusColor = 'error'
                break
              case 'E1':
                item.StatusDetails = 'Failed'
                item.StatusColor = 'error'
                break
            }
            break
          case 'Invoice':
            if (item.Balance <= 0) {
              item.StatusDetails = 'Paid'
              item.StatusColor = 'success'
            } else if (item.Balance > 0 && item.Balance != item.Amount) {
              item.StatusDetails = 'Partially Paid'
              item.StatusColor = 'warning'
            } else {
              item.StatusDetails = 'Posted'
              item.StatusColor = 'warning'
            }
            break
          case 'Credit Note':
            item.StatusDetails = 'Posted'
            item.StatusColor = 'success'
            break
          case 'Debit Note':
            item.StatusDetails = 'Posted'
            item.StatusColor = 'success'
            break
        }
      })
    },
    formatDate(date) {
      if (date) {
        let YYYYMMDD = dayjs(date, 'YYYY-MM-DD')
        return YYYYMMDD.format('DD MMM, YYYY')
      }
    },
    formatAmount(value) {
      var absValue = Math.abs(value)
      var returnString = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      }).format(absValue)
      return value < 0 ? '-' + returnString : returnString
    },
    getTypeColour(item) {
      switch(item) {
        case 'Invoice':
          return 'primary'
        case 'Payment':
          return 'green'
        case 'Credit Note':
          return 'error'
        case 'Debit Note':
          return 'secondary'
      }
    }
  },
}
</script>

<style scoped>
.table-card {
  position: relative;
  margin: 0 auto;
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: white;
}
.white-chip {
  height: 20px;
  color: white !important;
  font-size: 70%;
  font-weight: bold;
  padding-bottom: 0px;
  margin-top: -2px;
  margin-bottom: -1px;
}
.SolidButton {
  height: 20px;
  min-width: 50px !important;
  margin-top: -2px;
  margin-bottom: -2px;
  border-color: #4caf50 !important;
  border-width: 1px !important;
  border-style: solid !important;
  color: white !important;
  box-shadow: none !important;
  background-color: #4caf50 !important;
}
.total-amount {
  color: black !important;
  font-size: 14px;
  font-weight: 600;
}
div.layout.actions-append {
  display: flex !important;
  width: 1380px !important;
  padding-top: 20px !important;
  margin-bottom: -50px !important;
  justify-content: flex-end !important;
}
button:nth-child(1) {
  margin-right: 0px !important;
}
.ml-4 > button:nth-child(1) {
  margin-right: -20px !important;
}
</style>