<template>
    <v-container pa-0 mt-4>
        <the-loading ref="loading" />
        <v-layout row>
            <v-flex xs3.5 />
            <v-flex xs5>
                <stripe-element-card
                    v-if="publishableKey"
                    ref="elementRef"
                    :pk="publishableKey"
                    @token="tokenCreated"
                    :hidePostalCode=true
                />
            </v-flex>
            <v-flex xs3.5 />
        </v-layout>
        <stripe-confirmation-dialog
            ref="confirmationDialog"
            :customer="customer"
            :clientToken="clientToken"
            :invoiceToken="accountToken"
            :invoiceNumber="invoiceNumber"
            :invoiceAmount="invoiceAmount"
            @getIdempotencyToken="getIdempotencyToken"
        />
    </v-container>
</template>

<script>
import axios from "axios"
import { StripeElementCard } from '@vue-stripe/vue-stripe'
import StripeConfirmationDialog from "../MyAccount40/StripeConfirmationDialog.vue"
import TheLoading from "@/components/TheLoading.vue"
import Bus from '../../bus'

export default {
    components: {
        StripeElementCard,
        StripeConfirmationDialog,
        TheLoading
    },
    props: {
        customer: String,
        clientToken: String,
        name: String,
        email: String,
        accountToken: String,
        vizibillAppUrl: String,
        invoiceNumber: String,
        invoiceAmount: Number,
        invoices: Array,
        paymentMethod: String,
        defaultPayment: Boolean,
        userName: String
    },
    data() {
        return {
            token: null,
            publishableKey: null,
            idempotencyToken: '',
            formType: '',
            cardOnFile: false,
            showIframe: false
        };
    },
    async mounted() {
        await this.getPubKey()
        this.getIdempotencyToken()
    },
    methods: {
        async submit(formType){
            this.$refs.loading.processing(true)
            this.formType = formType
            await this.$refs.elementRef.submit()
            this.$refs.loading.processing(false)
        },
        async submitExistingCard(formType, cardId) {
            this.$refs.loading.processing(true)
            this.formType = formType
            this.cardOnFile = true
            await this.tokenCreated(cardId)
            this.$refs.loading.processing(false)
        },
        async tokenCreated (token) {
            this.$refs.loading.processing(true)
            if (this.cardOnFile) {
                this.cardId = token
            } else {
                this.token = token
            }
            let card = {
                BAN: this.$props.customer,
                payGateToken: this.$props.clientToken,
                email: this.$props.email,
                name: this.$props.name,
                idempotencyToken: this.idempotencyToken,
                Amount: this.$props.invoiceAmount,
                Currency: 'usd',
                DefaultPayment: this.$props.defaultPayment,
                UserName: this.$props.userName,
                CardOnFile: this.cardOnFile
            }
            if (this.cardOnFile == true) {
                card.card = this.cardId
            } else {
                card.card = this.token.id,
                card.ccDetails = {
                    ccNumber: this.token.card.last4
                }
            }
            var url
            if (this.formType === 'addCard') {
                await axios.post(process.env.VUE_APP_PGAPI + "api/card/post", card)
                    .then(res => {
                        if (res.status == 200) {
                            Bus.$emit('alert', {
                                color: 'success',
                                message: 'Card details saved successfully!'
                            })
                            this.$router.push({ 
                                name: 'PaymentConsole',
                                query: {
                                    token: this.accountToken,
                                }
                            })
                        }
                    })
                    .catch((error) =>{
                        Bus.$emit('alert', {
                            color: 'error',
                            message: `There was an error saving your card details, please try again.`
                        })
                        this.getIdempotencyToken()
                        console.log(error);
                    });
            } else if (this.formType === 'PayNow') {
                card.InvoiceNumber = this.$props.invoiceNumber
                card.InvoiceQuantity = "Single"
                await axios.post(process.env.VUE_APP_PGAPI + "api/card/payment", card)
                    .then(res => {
                        if (res.status == 200 && res.data.next_action != null) {
                            url = res.data.next_action.use_stripe_sdk.stripe_js
                            this.$refs.confirmationDialog.open(url)
                        } else if (res.status == 200) {
                            Bus.$emit('alert', {
                                color: 'success',
                                message: 'Payment processed successfully!'
                            })
                            this.$router.push({ 
                                name: 'PaymentConsole',
                                query: {
                                    token: this.accountToken,
                                }
                            })
                        }
                    })
                    .catch((error) =>{
                        Bus.$emit('alert', {
                            color: 'error',
                            message: `There was an error processing your payment, please try again.`
                        })
                        this.getIdempotencyToken()
                        console.log(error);
                    });
            } else if (this.formType === 'PayAll') {
                card.Invoices = this.$props.invoices
                card.InvoiceQuantity = "Multiple"
                await axios.post(process.env.VUE_APP_PGAPI + "api/card/pay-all", card)
                    .then(res => {
                        if (res.status == 200 && res.data.next_action != null) {
                            url = res.data.next_action.use_stripe_sdk.stripe_js
                            this.$refs.confirmationDialog.open(url)
                        } else if (res.status == 200) {
                            Bus.$emit('alert', {
                                color: 'success',
                                message: 'Payment processed successfully!'
                            })
                            this.$router.push({ 
                                name: 'PaymentConsole',
                                query: {
                                    token: this.accountToken,
                                }
                            })
                        }
                    })
                    .catch((error) =>{
                        Bus.$emit('alert', {
                            color: 'error',
                            message: `There was an error processing your payment, please try again.`
                        })
                        this.getIdempotencyToken()
                        console.log(error);
                    });
            }
            this.$refs.loading.processing(false)
        },
        async getPubKey() {
            let pubKey = await axios.post(process.env.VUE_APP_PGAPI + "api/values/stripepubkey", {PayGateToken: this.$props.clientToken})
            this.publishableKey = pubKey.data
        },
        getIdempotencyToken() {
            this.idempotencyToken = self.crypto.randomUUID()
        }
    }
}
</script>